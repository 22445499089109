/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../css/dashboardTaskDetails.module.css';

function DashboardTaskDetails({ venue, dayData, cardError }) {
  const history = useHistory();
  const [localDayData, setLocalDayData] = useState(null);

  useEffect(() => {
    setLocalDayData(dayData?.submitted);
  }, [dayData]);

  return (
    <div className={styles.dashboardTaskDetails}>
      <div className={styles.venueContainer}>
        <h3>{venue}</h3>
      </div>
      <div className={styles.submitted_Container}>
        <h5>Submitted:</h5>
        {localDayData?.flat()?.map((data, i) => (
          <div className={styles.report_Container}>
            <h5>
              {i + 1}
              .
              {' '}
              {data?.Emp_Name || ''}
            </h5>
            <div className={styles.projects_Container}>
              {data?.Project.map((d) => (
                <h6
                  className={styles.projects_items}
                  onClick={() => {
                    localStorage.setItem('projectName', d.toUpperCase());
                    history.push('/Admin/Project/SinglePage');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {'> '}
                  {d}
                </h6>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.notSubmitted_Container}>
        <h5>Not Submitted:</h5>
        <div className={styles.notSubmitted_Container_child}>
          {dayData?.notSubmitted.map((d, i) => (
            <div className={styles.report_Container}>
              <h5>
                {i + 1}
                .
                {' '}
                {d}
              </h5>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DashboardTaskDetails;

// [
//   [
//       {
//           "Emp_Name": "Yash Chandel",
//           "Project": [
//               "CONNECTPAY1"
//           ],
//           "TimeSpend": 8,
//           "Date": "2022-02-18"
//       },
//       {
//           "Emp_Name": "Vishal Kumar",
//           "Project": [
//               "CONNECTPAY1",
//               "TENNIS",
//               "NICK SMALLMAN"
//           ],
//           "TimeSpend": 32,
//           "Date": "2022-02-18"
//       }
//   ]
// ]
