import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DataContext = React.createContext();

const sender = {
  CompanyName: '',
  Country: '',
  FirstName: '',
  LastName: '',
  Email: '',
  Address1: '',
  Address2: '',
  Phone: '',
  Website: '',
};

export function DataContextProvider({ children }) {
  const [senderDetails, setSenderDetails] = useState({
    ...sender,
  });
  const [recieverDetails, setRecieverDetails] = useState({
    ...sender,
  });
  return (
    <DataContext.Provider
      value={{
        senderDetails,
        setSenderDetails,
        recieverDetails,
        setRecieverDetails,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
DataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default DataContext;
