import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from 'react-router-dom';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import AddProject from '../components/AddProject';
import Sidebar from '../components/Sidebar';
import 'react-spring-modal/styles.css';
import configdata from '../config.json';

function Project() {
  const [addProject, setAddProject] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [id1, setId1] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [deleteProject] = useState('true');
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const token = localStorage.getItem('token');
  const projectData = async (setState) => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}project`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    })
      .then((res) => res.json())
      .then((data) => setProjects(data))
      .catch((error) => error);

    setState(true);
  };

  const str = searchItem;
  const cleanStr = str.trim();
  useEffect(() => {
    projectData(setLoading);
  }, []);

  const DeletedData = {
    Delete: deleteProject,
  };
  const history = useHistory();
  return (
    <div>
      <Sidebar />

      <div id="page-wrapper" className="gray-bg dashbard-1">
        {token.length !== 0 ? (
          <div className="content-main">
            <div className="content-all">
              <div className="search-right">
                <form className="navbar-left-right">
                  <div className="searchbar">
                    <i className="fa fa-search" aria-hidden="true" />
                    <input
                      placeholder="Search Project..."
                      type="text"
                      onChange={(e) => {
                        setSearchItem(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setAddProject((previousDisplay) => !previousDisplay);
                    }}
                    className="add-btn"
                  >
                    <i className="fa fa-plus" aria-hidden="true" />
                    Add Project
                  </button>
                </form>
              </div>
              <div
                className="projects-data"
                style={{ marginTop: '1rem', minHeight: '77vh' }}
              >
                <ol className="projects-list" style={{ marginLeft: '1rem' }}>
                  {loading ? (
                    projects
                      ?.filter((d) => {
                        const del = d.Delete;
                        if (del === 'false') {
                          return d;
                        }
                        return false;
                      })
                      .filter((d) => {
                        if (cleanStr === '') {
                          return d;
                        }
                        if (
                          d.ProjectName.toLowerCase().includes(
                            cleanStr.toLowerCase(),
                          ) || d.ClientName.toLowerCase().includes(
                            cleanStr.toLowerCase(),
                          )
                        ) {
                          return d;
                        }
                        return false;
                      })
                      .map(({ ProjectName, ClientName, _id }, index) => (
                        <li style={{ flexDirection: 'column' }}>
                          <h2
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const projectIdData = _id;
                              localStorage.setItem('projectId', projectIdData);
                              const projectName = ProjectName;
                              localStorage.setItem('projectName', projectName);
                              history.push('/Admin/Project/SinglePage');
                            }}
                          >
                            {index + 1}
                            .
                            {' '}
                            {ProjectName}
                          </h2>
                          <p>{ClientName}</p>
                          <span
                            style={{
                              marginRight: '1.7rem',
                            }}
                            className="icon-right dlt-icon"
                            onClick={() => {
                              const id0 = _id;

                              setId1(id0);
                              setOpen(true);
                            }}
                          >
                            <Tooltip title="Delete" arrow>
                              <i className="fa fa-trash" />
                            </Tooltip>
                          </span>

                          <span
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              const projectIdData = _id;

                              localStorage.setItem('projectId', projectIdData);
                            }}
                            className="icon-right"
                          >
                            <i className="fa fa-angle-right" />
                          </span>
                        </li>
                      ))
                  ) : (
                    <div id="cover-spin" />
                  )}
                </ol>
                <div className="clearfix" />
                <CenterModal isOpen={isOpen} onDismiss={() => setOpen(false)}>
                  <p>Are you sure you want to delete it?</p>

                  <ModalCloseTarget>
                    <div className="button-Wrap">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={() => {
                          fetch(
                            `${configdata.REACT_APP_SERVER_URL}project/update/${id1}`,
                            {
                              method: 'PUT',
                              headers: {
                                'Content-Type': 'application/json',
                                'x-auth-token': token,
                              },
                              body: JSON.stringify(DeletedData),
                            },
                          ).then((res) => res.json());
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        style={{
                          marginLeft: '8px',
                          backgroundColor: '#333',
                        }}
                        className="btn btn-default"
                      >
                        Close
                      </button>
                    </div>
                  </ModalCloseTarget>
                </CenterModal>
                <div className="projects_view_button" />
                <AddProject
                  addProject={addProject}
                  setAddProject={setAddProject}
                  ProjectData={projectData}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className="copy">
          <p> © 2022 | All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Project;
