/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faUser,
  faPlusCircle,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import SuccessModel from '../components/SuccessModel';
import BlogContext from '../utils/Context';
import AddSenderDetails from '../components/AddSenderDetails';
import Sidebar from '../components/Sidebar';
import DataContext from '../utils/DataContext';
import '../css/Singlepage.css';
import EditInvoiceUserDetail from '../components/EditInvoiceUserDetail';
import deleteInvoiceUser from '../utils/deleteInvoiceUser';
import styles from '../css/EditInvoiceUserDetail.module.css';
import configdata from '../config.json';

const options = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Tax Invoice', label: 'Tax Invoice' },
  { value: 'Commercial Invoice', label: 'Commercial Invoice' },
  { value: 'Proforma Invoice', label: 'Proforma Invoice' },
  { value: 'Bill', label: 'Bill' },
  { value: 'Receipt', label: 'Receipt' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Quote', label: 'Quote' },
  { value: 'Estimate', label: 'Estimate' },
];

// onClick={() => setShowSuccess(
//   true,
//   data._id,
//   'senderInfo',
//   getSenderInfo,
// )}

const SuccesRawData = {
  should: false,
  id: '',
  type: '',
  callback: null,
};

function Invoice() {
  const [showSuccess, setShowSuccess] = useState({
    ...SuccesRawData,
  });
  const { senderDetails, recieverDetails } = useContext(DataContext);

  const [addSenderDetail, setAddSenderDetail] = useState(false);
  const [addRecieverDetail, setAddRecieverDetail] = useState(false);
  const [display, setDisplay] = useState(false);
  const [recieverDisplay, setRecieverDisplay] = useState(false);
  const [senderInfoData, setSenderInfoData] = useState([]);
  const [recieverInfoData, setRecieverInfoData] = useState([]);
  const [senderResult, setSenderResult] = useState([]);
  const [recieverResult, setRecieverResult] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [invoice, setInvoice] = useState('Invoice');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceDueDate, setInvoiceDueDate] = useState('');
  const [invoiceData, setInvoiceData] = useState([]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [corrunceyInfo, setCorrunceyInfo] = useState('INR');
  const [successModel, setSuccessModel] = useState(false);
  const { editInvoiceUserDetail, setEditInvoiceUserDetail } = useContext(BlogContext);

  const [itemFields, setItemFields] = useState([
    {
      Item: '',
      HRS: 0,
      Rate: 0,
      Description: '',
    },
  ]);

  const token = localStorage.getItem('token');

  const getSenderInfo = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}senderinfo`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setSenderInfoData(data));
  };

  const getrecieverInfo = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}recieverinfo`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setRecieverInfoData(data));
  };

  const getInvoiceData = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}invoicedata`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        const invoiceData1 = data.data;

        setInvoiceData(data.data);
        if (invoiceData1.length === 0) {
          setInvoiceNumber(1);
        } else {
          invoiceData1.map((d) => setInvoiceNumber(d.InvoiceNumber + 1));
        }
      });
  };

  useEffect(() => {
    getSenderInfo();
    getrecieverInfo();
    getInvoiceData();
  }, []);

  // const defaultInvoiceNumber = invoiceData.map((data) => data.InvoiceNumber);
  // const num = defaultInvoiceNumber[0];
  // const newInvoiceNumber = num + 1;
  // console.log(newInvoiceNumber);

  const InvoiceDates = {
    invoiceDate,
    invoiceDueDate,
  };
  const Subtotal = itemFields.map((item) => item.HRS * item.Rate);
  const totalAmount = Subtotal.reduce((a, b) => a + b, 0);

  const senderData = {
    Invoice: invoice,
    Currency: corrunceyInfo,
    SenderResult: senderResult,
    RecieverResult: recieverResult,
    InvoiceNumber: invoiceNumber,
    Dates: InvoiceDates,
    Items: itemFields,
    TotalAmount: totalAmount,
  };

  const addInvoiceData = async () => {
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}invoicedata/adddata`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(senderData),
      },
    )
      .then((res) => res.json())
      .catch((err) => setSuccessModel(false));
    if (result.status === 'ok') {
      setSuccessModel(true);
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...itemFields];
    list[index][name] = value;
    // eslint-disable-next-line array-callback-return
    const item = list.map((items, i) => {
      const array = list[i];
      array.totalValue = items.HRS * items.Rate;
      return array;
    });
    setItemFields(item);
  };

  function handleAdd() {
    setItemFields([
      ...itemFields,
      {
        Item: '',
        HRS: 0,
        Rate: 0,
        Description: '',
      },
    ]);
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div id="page_content" className="content-main">
          <div className="content_invoice">
            <div id="page_invoice" className="invoice">
              <div className="content_top" style={{ flex: '1' }}>
                <div className="logo_img" style={{ marginTop: '0px' }}>
                  <h2>
                    <span className="logo_img">
                      <img
                        src="/images/logo.png"
                        alt=""
                        className="mobile_img"
                      />
                    </span>
                  </h2>
                </div>
                <div className="invoice_select" style={{ marginTop: '0px' }}>
                  <select onChange={(e) => setCorrunceyInfo(e.target.value)}>
                    <option value="" disabled selected>
                      Select Currency
                    </option>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </select>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flex: '0',
                    marginTop: '0rem',
                  }}
                >
                  <div className="invoice_select">
                    <select onChange={(e) => setInvoice(e.target.value)}>
                      {options.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="btns">
                  <div className="invoice_download">
                    <button
                      type="button"
                      onClick={() => {
                        setDisableBtn(true);
                        setTimeout(() => {
                          setDisableBtn(false);
                        }, 3000);
                        if (
                          senderData.SenderResult !== []
                          && senderData.RecieverResult !== []
                        ) {
                          addInvoiceData();
                        } else {
                          setActive(true);
                          setTimeout(() => {
                            setActive(false);
                          }, 3000);
                        }
                      }}
                      disabled={disableBtn}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              {display === true || recieverDisplay === true ? (
                <div className="cmn_modal popup">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body" style={{ padding: '0px' }}>
                        <span
                          onClick={() => {
                            display
                              ? setDisplay(false)
                              : setRecieverDisplay(false);
                          }}
                          className="close"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            color="rgb(168, 156, 156)"
                          />
                        </span>
                        <ul className="modal_ul">
                          {display === true
                            ? senderInfoData.map((data) => (
                              <li>
                                <div className="list_div">
                                  <div className="div_left">
                                    <FontAwesomeIcon icon={faUser} />
                                  </div>
                                  <div className="div_right">
                                    <div
                                      onClick={async () => {
                                        setDisplay(false);
                                        const id = data._id;
                                        await fetch(
                                          `${configdata.REACT_APP_SERVER_URL}senderInfo/${id}`,
                                          {
                                            method: 'GET',
                                            mode: 'cors',
                                            cache: 'no-cache',
                                            credentials: 'same-origin',
                                            headers: {
                                              'Content-Type':
                                                  'application/json',
                                              'x-auth-token': token,
                                            },
                                            redirect: 'follow',
                                            referrerPolicy: 'no-referrer',
                                          },
                                        )
                                          .then((res) => res.json())
                                          .then((data1) => {
                                            setSenderResult(data1.data);
                                            if (data.status === 'ok') {
                                              setDisplay(false);
                                            }
                                          });
                                      }}
                                    >
                                      <h3>{data.FirstName}</h3>
                                      <p>{data.Email}</p>
                                    </div>
                                    <div
                                      style={{
                                        width: '60px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => setShowSuccess({
                                          ...showSuccess,
                                          should: true,
                                          id: data._id,
                                          type: 'senderInfo',
                                          callback: getSenderInfo,
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        onClick={() => setEditInvoiceUserDetail({
                                          shouldOpen: true,
                                          id: data._id,
                                          mode: 'senderInfo',
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                            : recieverInfoData.map((data) => (
                              <li>
                                <div className="list_div">
                                  <div className="div_left">
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      size="sm"
                                    />
                                  </div>
                                  <div className="div_right">
                                    <div
                                      onClick={async () => {
                                        setRecieverDisplay(false);
                                        const id1 = data._id;
                                        await fetch(
                                          `${configdata.REACT_APP_SERVER_URL}recieverInfo/${id1}`,
                                          {
                                            method: 'GET',
                                            mode: 'cors',
                                            cache: 'no-cache',
                                            credentials: 'same-origin',
                                            headers: {
                                              'Content-Type':
                                                  'application/json',
                                              'x-auth-token': token,
                                            },
                                            redirect: 'follow',
                                            referrerPolicy: 'no-referrer',
                                          },
                                        )
                                          .then((res) => res.json())
                                          .then((data2) => {
                                            setRecieverResult(data2.data);
                                            if (data.status === 'ok') {
                                              setRecieverDisplay(false);
                                            }
                                          });
                                      }}
                                      className="div_right_inner"
                                    >
                                      <h3>{data.FirstName}</h3>
                                      <p>{data.Email}</p>
                                    </div>
                                    <div
                                      style={{
                                        width: '60px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => setShowSuccess({
                                          ...showSuccess,
                                          should: true,
                                          id: data._id,
                                          type: 'recieverInfo',
                                          callback: getrecieverInfo,
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        onClick={() => setEditInvoiceUserDetail({
                                          shouldOpen: true,
                                          id: data._id,
                                          mode: 'recieverInfo',
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div
                        className="modal_bottom"
                        onClick={() => {
                          if (display) {
                            setAddSenderDetail((prevDisplay) => !prevDisplay);
                            setDisplay(false);
                          } else {
                            setAddRecieverDetail((prevDisplay) => !prevDisplay);
                            setRecieverDisplay(false);
                          }
                        }}
                      >
                        <h3>
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            style={{ margin: '8px', fontSize: '2rem' }}
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="content_center">
                <div className="center_row1">
                  <div
                    className="heading_left"
                    onClick={(e) => setDisplay(true)}
                  >
                    <div style={{ margin: '10px' }}>
                      <p className="heading_text">From</p>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? senderDetails.CompanyName
                            : senderResult.CompanyName}
                        </h4>
                      </span>

                      <span>
                        <p style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? senderDetails.Email
                            : senderResult.Email}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? senderDetails.Address1
                            : senderResult.Address1}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? senderDetails.Address2
                            : senderResult.Address2}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? senderDetails.Country
                            : senderResult.Country}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {senderResult === []
                            ? senderDetails.Phone
                            : senderResult.Phone}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div
                    className="heading_right "
                    onClick={(e) => setRecieverDisplay(true)}
                  >
                    <div style={{ margin: '10px' }}>
                      <p className="heading_text">To</p>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? recieverDetails.CompanyName
                            : recieverResult.CompanyName}
                        </h4>
                      </span>

                      <span>
                        <p style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? recieverDetails.Email
                            : recieverResult.Email}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? recieverDetails.Address1
                            : recieverResult.Address1}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? recieverDetails.Address2
                            : recieverResult.Address2}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? recieverDetails.Country
                            : recieverResult.Country}
                        </p>
                      </span>
                      <span>
                        <p style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? recieverDetails.Phone
                            : recieverResult.Phone}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="center_row2">
                  <div className="row2_left">
                    <div className="row2_input_field">
                      <p>Invoice No : </p>
                      <span className="row2_input">
                        <input
                          type="number"
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          value={invoiceNumber}
                        />
                      </span>
                    </div>

                    <div className="row2_input_field">
                      <p>Invoice Date : </p>
                      <span className="row2_input">
                        <input
                          type="date"
                          onChange={(e) => setInvoiceDate(e.target.value)}
                          style={{ height: '25px', width: '140px' }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="row2_right">
                    <div className="row2_input_field">
                      <p>Due:</p>
                      <span className="row2_input">
                        <input
                          type="date"
                          onChange={(e) => setInvoiceDueDate(e.target.value)}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="content_bottom">
                  <div className="content_bottom_heading">
                    <div className="bottom_heading_left">
                      <span>
                        <p>Item</p>
                      </span>
                    </div>
                    <div
                      className="bottom_heading_right"
                      style={{ paddingRight: '0', paddingLeft: '6.5rem' }}
                    >
                      <span style={{}}>
                        <p>HRS/QTY</p>
                      </span>
                      <span>
                        <p>Rate</p>
                      </span>

                      <span style={{ textAlign: 'right' }}>
                        <p>Subtotal</p>
                      </span>
                    </div>
                  </div>
                  <div className="">
                    {itemFields.map((x, i) => (
                      <div key={i}>
                        <div className="rate-field_row1">
                          <div className="input_field">
                            <input
                              type="text"
                              id="input-field"
                              name="Item"
                              value={x.Item || ''}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div className="input_field">
                            <input
                              type="number"
                              name="HRS"
                              value={x.HRS || ''}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div
                            className="input_field"
                            style={{ paddingRight: '5rem' }}
                          >
                            <input
                              type="number"
                              name="Rate"
                              value={x.Rate || ''}
                              onChange={(e) => {
                                handleInputChange(e, i);
                              }}
                            />
                          </div>
                          <div
                            className="input_field"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              paddingRight: '1rem',
                            }}
                          >
                            <span style={{ marginRight: '6px' }}>
                              {corrunceyInfo}
                            </span>
                            <span>
                              {' '}
                              <p>{x.Rate * x.HRS}</p>
                            </span>
                          </div>
                        </div>
                        <div className="rate-field_row1">
                          <div
                            className="input_field1"
                            style={{ width: 'auto' }}
                          >
                            <textarea
                              className="input_description textareaDesc"
                              type="text"
                              placeholder="Description"
                              name="Description"
                              value={x.Description}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="content_bottom_add_new">
                    <span
                      style={{ marginTop: '5px', color: 'gray' }}
                      onClick={() => {
                        handleAdd();
                      }}
                    >
                      <p>Add New Invoice Item</p>
                    </span>
                  </div>
                </div>
                <div className="content_summary newVioceWrap">
                  <div className="content_summary_body">
                    <div className="summary_heading">
                      <p>Invoice Summary</p>
                    </div>
                    <div className="summary_total">
                      <ul>
                        <li>
                          <div className="summary_total_list">
                            <span>Subtotal</span>

                            <p style={{ color: 'gray' }}>
                              <span style={{ marginRight: '5px' }}>
                                {corrunceyInfo}
                              </span>
                              <span>{totalAmount}</span>
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="summary_total_list">
                            <div
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <span style={{ marginTop: '9px' }}>Total</span>
                              <span
                                className="courrency-select"
                                style={{ marginLeft: '5px' }}
                              />
                            </div>

                            <p
                              className="summarylist_p"
                              style={{ color: 'gray', marginTop: '6px' }}
                            >
                              <span style={{ marginRight: '6px' }}>
                                {corrunceyInfo}
                              </span>
                              <span>{totalAmount}</span>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loading ? <div id="cover-spin" /> : null}
        </div>
        <AddSenderDetails
          addSenderDetail={addSenderDetail}
          setAddSenderDetail={setAddSenderDetail}
          text1="Company/Client Name"
          text2="Website"
          button="Set Sender Details"
        />
        <AddSenderDetails
          addSenderDetail={addRecieverDetail}
          setAddSenderDetail={setAddRecieverDetail}
          text1="Name/Comapny Name"
          text2="Extra Data"
          button="Submit"
        />
      </div>
      {editInvoiceUserDetail.shouldOpen && (
        <EditInvoiceUserDetail
          id={editInvoiceUserDetail.id}
          mode={editInvoiceUserDetail.mode}
        />
      )}
      {showSuccess.should && (
        <SuccessModel>
          <div>
            <h3>Are you sure to delete it?</h3>
          </div>
          <div className={styles.Model__btn__container}>
            <button
              type="button"
              style={{ background: '#e74c3c', border: 'none' }}
              onClick={() => setShowSuccess({ ...SuccesRawData })}
            >
              Cancel
            </button>
            <button
              type="submit"
              style={{ background: '#2ecc71', border: 'none' }}
              onClick={() => {
                deleteInvoiceUser(
                  showSuccess.id,
                  showSuccess.type,
                  showSuccess.callback,
                );
                setShowSuccess({ ...SuccesRawData });
              }}
            >
              Confirm
            </button>
          </div>
        </SuccessModel>
      )}
      {successModel && !loading && (
        <SuccessModel>
          <div>
            <h3>Invoice Data Added Succesfully</h3>
          </div>
          <div className={styles.Model__btn__container}>
            <button type="submit" onClick={() => setSuccessModel(false)}>
              Ok
            </button>
          </div>
        </SuccessModel>
      )}
      <div className="copy">
        <p> © 2022 | All Rights Reserved</p>
      </div>
    </div>
  );
}

export default Invoice;
