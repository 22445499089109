import React, { useState, useEffect } from 'react';
import '../css/SingleInvoice.css';
import moment from 'moment';
import JSPDF from 'jspdf';
import { useHistory } from 'react-router-dom';
import Sidebar from './Sidebar';
import configdata from '../config.json';

function SingleInvoicePage() {
  const [invoiceData, setInvoiceData] = useState();
  const InvoiceId = localStorage.getItem('invoiceId');
  const history = useHistory();

  const token = localStorage.getItem('token');

  const getInvoiceData = () => {
    fetch(`${configdata.REACT_APP_SERVER_URL}invoicedata/${InvoiceId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        setInvoiceData(data.data);
      });
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  const generatePdf = () => {
    const doc = new JSPDF('p', 'pt', 'a4', true);
    doc.html(document.querySelector('#Invoice-Content'), {
      callback(pdf) {
        pdf.save('invoice.pdf');
      },
    });
  };
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div id="page_content" className="content-main">
          <div className="invoice_header">
            <div className="invoice_headingg">
              <div className="heading_leftt">
                Invoice
                {' '}
                <span>
                  #
                  {invoiceData ? invoiceData.InvoiceNumber : null}
                </span>
              </div>
              <div className="heading_rightt">
                <button
                  type="button"
                  className="btn_edit"
                  onClick={() => history.push('/Admin/Invoice/EditInvoice')}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn_download"
                  onClick={generatePdf}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
          <div className="invoice_content">
            <div id="Invoice-Content">
              <div className="content-Top">
                <div className="logo_img" style={{ marginTop: '12px' }}>
                  <h2>
                    <span className="logo_img">
                      <img
                        src="/images/logo.png"
                        alt=""
                        className="mobile_img"
                      />
                    </span>
                  </h2>
                </div>
                <div className="invoice_type">
                  <h4>{invoiceData ? invoiceData.InvoiceType : null}</h4>
                </div>
              </div>

              <div className="invoice_info">
                <div className="sender_info">
                  <h4>From</h4>
                  <div className="senderDetails">
                    <p>
                      {invoiceData ? invoiceData.SenderInfo.CompanyName : null}
                    </p>
                    <p>{invoiceData ? invoiceData.SenderInfo.Email : null}</p>
                    <p>
                      {invoiceData ? invoiceData.SenderInfo.Address1 : null}
                    </p>
                    <p>{invoiceData ? invoiceData.SenderInfo.Phone : null}</p>
                  </div>
                </div>

                <div className="reciever_info">
                  <h4>To</h4>
                  <div className="senderDetails">
                    <p>
                      {invoiceData
                        ? invoiceData.RecieverInfo.CompanyName
                        : null}
                    </p>
                    <p>{invoiceData ? invoiceData.RecieverInfo.Email : null}</p>
                    <p>
                      {invoiceData ? invoiceData.RecieverInfo.Address1 : null}
                    </p>
                    <p>{invoiceData ? invoiceData.RecieverInfo.Phone : null}</p>
                  </div>
                </div>
              </div>
              <div className="invoice_dates">
                <div className="date_col1">
                  <h4>
                    <span style={{ fontWeight: '600', color: '#443f3f' }}>
                      Invoice No. :
                    </span>
                    {' '}
                    {invoiceData ? invoiceData.InvoiceNumber : null}
                  </h4>
                  <h4>
                    {' '}
                    <span style={{ fontWeight: '600', color: '#443f3f' }}>
                      {' '}
                      Invoice Date :
                    </span>
                    {' '}
                    {invoiceData
                      ? moment(invoiceData.Dates.invoiceDate).format(
                        'DD-MM-YYYY',
                      )
                      : null}
                  </h4>
                </div>
                <div className="date_col2">
                  <h4>
                    {' '}
                    <span style={{ fontWeight: '600', color: '#443f3f' }}>
                      Due Date :
                    </span>
                    {' '}
                    {invoiceData
                      ? moment(invoiceData.Dates.invoiceDueDate).format(
                        'DD-MM-YYYY',
                      )
                      : null}
                  </h4>
                </div>
              </div>
              <div className="invoiceItemsWrap">
                <div className="invoice_items_headingg">
                  <div className="headingg_left">
                    <h4>Item</h4>
                  </div>
                  <div className="headingg_right">
                    <h4>HRS/QTY</h4>
                    <h4>Rate</h4>
                    <h4>Subtotal</h4>
                  </div>
                </div>

                <div className="invoice_items">
                 
                  {invoiceData?.Items?.map((data) => (
                    <>
                   
                      <div className="item_row1">
                        <div className="row1_left">
                          <div className="row1_item textTd">{data.Item}</div>
                        </div>
                        <div className="row1_right">
                          <div className="row1_QTY textTd">
                            {data.HRS}
                            {' '}
                          </div>
                          <div className="row1_Rate textTd">{data.Rate}</div>
                          <div className="row1_Subtotal textTd">
                            {invoiceData?.Currency}
                            <span style={{ paddingLeft: '7px' }}>
                              {data.totalValue}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="item_row2">
                        <div className="row2_Description textTd">
                          {data.Description}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>

              <div className="totalSummaryInvoice">
                <div className="invoice_summaryy">
                  <div className="summary_headingg">
                    <h4>Invoice Summary</h4>
                  </div>
                  <div className="summary_contentt">
                    <div className="summary_content_row1">
                      <h4>Subtotal</h4>
                      <h4>
                        {' '}
                        {invoiceData?.Currency}
                        <span style={{ paddingLeft: '7px' }}>
                          {invoiceData?.TotalAmount}
                        </span>
                      </h4>
                    </div>
                    <div className="summary_content_row2">
                      <h4>Total</h4>
                      <h4>
                        {' '}
                        {invoiceData?.Currency}
                        <span style={{ paddingLeft: '7px' }}>
                          {invoiceData?.TotalAmount}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleInvoicePage;
