import React, { useState, useEffect } from 'react';
import '../css/AddEmployee.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Modal from './Modal';
import configdata from '../config.json';

const options = [
  { value: 'Front End Designer', label: 'Front End Designer' },
  { value: 'Web Developer', label: 'Web Developer' },
  { value: 'WebApp Developer', label: 'WebApp Developer' },
  { value: 'Mobile App Developer', label: 'Mobile App Developer' },
  { value: 'Graphic Designer', label: 'Graphic Designer' },
];

function EditEmployee({
  display,
  editDisplay,
  setEditDisplay,
  employeeInfo,
  employeeId,
}) {
  const [modal, setModal] = useState(false);
  const [userData, setUserData] = useState({
    FirstName: employeeInfo.FirstName,
    LastName: '',
    DOB: '',

    Designation: '',
    ContactNumber: '',
    editable: false,
  });

  const [password, setPassword] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    setUserData(employeeInfo);
    setUserData((prevData) => ({
      ...prevData,
      editable: true,
    }));
  }, [employeeInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const editData = {
      FirstName: userData.FirstName,
      LastName: userData.LastName,
      DOB: moment(userData.DOB).format('YYYY-MM-DD'),
      Password: password,
      Designation: userData.Designation,
      ContactNumber: userData.ContactNumber,
    };
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}app/employee/update/${employeeId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(editData),
      },
    ).then((res) => res.json());
    if (result.status === 'ok') {
      setEditDisplay((previousDisplay) => !previousDisplay);
      setModal((previousDisplay) => !previousDisplay);
    } else {
      console.log(result.error);
    }
  };

  return (
    <div>
      {editDisplay ? (
        <div className="cmn_modal popup" style={{ display }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <span
                  onClick={() => {
                    setEditDisplay((prevDisplay) => !prevDisplay);
                  }}
                  className="close"
                >
                  <FontAwesomeIcon icon={faTimes} color="rgb(168, 156, 156)" />
                </span>
                <form className="employee_details" onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="First Name "
                          name="FirstName"
                          value={userData.editable && userData.FirstName}
                          onChange={
                            userData.editable
                              ? (e) => {
                                setUserData((prevData) => ({
                                  ...prevData,
                                  FirstName: e.target.value,
                                }));
                              }
                              : null
                          }
                          type="text"
                        />
                        <i className="fa fa-user" />
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Last Name "
                          name="LastName"
                          type="text"
                          value={userData.editable && userData.LastName}
                          onChange={
                            userData.editable
                              ? (e) => {
                                setUserData((prevData) => ({
                                  ...prevData,
                                  LastName: e.target.value,
                                }));
                              }
                              : null
                          }
                        />
                        <i className="fa fa-user" />
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Email "
                          value={employeeInfo.Email}
                          className="edit-employee-email"
                        />
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="form-field">
                        <input
                          type="date"
                          id="birthday"
                          name="birthday"
                          value={userData.editable && userData.DOB}
                          onChange={
                            userData.editable
                              ? (e) => {
                                setUserData((prevData) => ({
                                  ...prevData,
                                  DOB: e.target.value,
                                }));
                              }
                              : null
                          }
                        />
                      </div>
                      <div className="form-field">
                        <input
                          type="text"
                          name="Password"
                          placeholder="Password"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <select
                          name="Designation"
                          id="Designation"
                          value={userData.editable && userData.Designation}
                          onChange={
                            userData.editable
                              ? (e) => {
                                setUserData((prevData) => ({
                                  ...prevData,
                                  Designation: e.target.value,
                                }));
                              }
                              : null
                          }
                        >
                          <option value="null" disbled selected>
                            Designation
                          </option>
                          {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Contact Number"
                          type="number"
                          min="0"
                          value={userData.editable && userData.ContactNumber}
                          onChange={
                            userData.editable
                              ? (e) => {
                                setUserData((prevData) => ({
                                  ...prevData,
                                  ContactNumber: e.target.value,
                                }));
                              }
                              : null
                          }
                          name="ContactNumber"
                        />
                        <i className="fa fa-phone" />
                      </div>
                    </div>
                    <div className="login-do">
                      <button
                        style={{ color: 'white' }}
                        type="submit"
                        className="login-sub adddetail-user"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Modal modal={modal} setModal={setModal} />
    </div>
  );
}

export default EditEmployee;
