import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import BlogContext from '../../utils/Context';
import configdata from '../../config.json';

function EditInvoicemodal({
  modal,
  setModal,
  refetchType,
  setLoading,
  Status,
}) {
  const { getInvoiceData } = useContext(BlogContext);

  const [updateInvoiceStatus, setInvoiceStatus] = useState('pending');
  const status = updateInvoiceStatus;

  const token = localStorage.getItem('token');

  const editedData = {
    InvoiceType: Status?.InvoiceType,
    Currency: Status?.Currency,
    SenderResult: Status?.SenderInfo,
    RecieverResult: Status?.RecieverInfo,
    Dates: Status?.Dates,
    InvoiceNumber: Status?.InvoiceNumber,
    Items: Status?.Items,
    Amount: Status?.TotalAmount,
    status,
  };
  const UpdateInvoiceStatus = async () => {
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}invoicedata/updateinvoice/${Status._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(editedData),
      },
    ).then((res) => res.json());
    if (result.status === 'ok') {
      setModal((prevDispaly) => !prevDispaly);
      getInvoiceData();
    }
  };

  return (
    <div>
      {modal ? (
        <div className="cmn_modal popup" id="successModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="tick_success_icon">
                  <span
                    className="close"
                    onClick={() => setModal((previousDisplay) => !previousDisplay)}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      color="rgb(168, 156, 156)"
                    />
                  </span>
                  <select onChange={(e) => setInvoiceStatus(e.target.value)}>
                    <option value="pending">{Status.status}</option>
                    <option
                      value={Status.status === 'Payed' ? 'Pending' : 'Payed'}
                    >
                      {Status.status === 'Payed' ? 'Pending' : 'Payed'}
                    </option>
                  </select>

                  <button
                    type="button"
                    className="okbtn"
                    onClick={() => {
                      UpdateInvoiceStatus();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default EditInvoicemodal;
