import configdata from '../config.json';

export default async function deleteInvoiceUser(id, mode, callback) {
  const token = localStorage.getItem('token');
  await fetch(`${configdata.REACT_APP_SERVER_URL}${mode}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token': token,
    },
  }).then(() => callback());
}
