/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Select from 'react-select';
import Sidebar from '../components/Sidebar';
import ExportToExcel from '../components/ExportToExcel';
import configdata from '../config.json';

function Reports() {
  const [project, setProject] = useState([]);
  const [isOpen, setOpen] = React.useState(false);
  const [id1, setId1] = useState('');
  const [employee, setEmployee] = useState([]);
  const [searchItem, setSearchItem] = useState([]);
  const [searchByName, setSearchByName] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [outputData, setOutputData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [sortData, setSortData] = useState(false);
  const [btnText, setBtnText] = useState('Sort Report');
  const [isFilterDataEmpty, setIsFilterDataEmpty] = useState(false);
  const filterByItem = searchItem.map((d) => d.label);
  const filterByName = searchByName.map((d) => d.value);

  const fileName = 'myfile';

  const token = localStorage.getItem('token');

  const projectData = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}project`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setProject(data));
  };

  const employeeData = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}app/employee`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setEmployee(data));
  };

  const searchedData = {
    Name: filterByName,
    Project: filterByItem,
    StartDate: moment(startDate).format('YYYY-MM-DD'),
    EndDate: moment(endDate).format('YYYY-MM-DD'),
  };

  const filterData = async () => {
    if (
      !startDate
      || !endDate
      || !startDate === ' '
      || endDate === ''
      || !isFilterDataEmpty
    ) {
      return null;
    }
    setFilterLoader(true);
    setTimeout(() => {
      setFilterLoader(false);
    }, 1000);
    setSearch(true);
    await setOutputData([]);
    fetch(`${configdata.REACT_APP_SERVER_URL}search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(searchedData),
    })
      .then(async (res) => res.json())

      .then((data) => {
        const filterUser = data?.result
          ?.filter((d) => {
            const del = d.Delete;
            if (del === 'false') {
              return d;
            }
            return false;
          })
          .map((d) => ({
            Emp_Name: d.Emp_Name,
            Project: d.Project,
            Date: d.Date,
            Description: d.Description,
            TimeSpend: d.TimeSpend,
          }));

        filterUser.sort((a, b) => new Date(b.Date) - new Date(a.Date));
        setOutputData(filterUser);
        setFilteredData(filterUser);
      });
  };

  useEffect(() => {
    if (sortData) {
      const hello = filteredData.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date),
      );

      setFilteredData(hello);
      setOutputData(hello);
      setBtnText('Sorted');
    }
  }, [sortData]);

  useEffect(() => {
    if (!sortData) {
      filterData();
      setBtnText('Sort Report');
    }
  }, [sortData]);

  useEffect(() => {
    projectData();
    employeeData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSearch(false);
    }, 1000);
  }, [search]);

  const ProjectOptions = project.filter((d) => d.Delete === 'false');

  const LabeledProjectOptions = ProjectOptions.map((d) => ({
    value: d._id,
    label: d.ProjectName,
  }));

  const EmployeeOptions = employee.map((d) => ({
    value: d.Email,
    label: `${d.FirstName} ${d.LastName}`,
  }));

  const handleSubmit = async (e) => {
    setIsFilterDataEmpty(true);
    e.preventDefault();
    filterData();
  };
  return (
    <div>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div className="content-main">
          <div className="content-all">
            <div className="projects_complete_detials">
              <div className="project-filters report-page-filters">
                <form method="POST" onSubmit={handleSubmit}>
                  <div className="filter-form">
                    <div className="formgrid">
                      <Select
                        placeholder="Project Name"
                        value={searchItem}
                        onChange={setSearchItem}
                        options={LabeledProjectOptions}
                        isMulti
                      />
                    </div>
                    <div className="formgrid">
                      <Select
                        placeholder="Employee Name"
                        value={searchByName}
                        onChange={setSearchByName}
                        options={EmployeeOptions}
                        isMulti
                      />
                    </div>
                    <div className="formgrid">
                      <input
                        className="datePicker"
                        type="date"
                        value={startDate}
                        onChange={(date) => setStartDate(date.target.value)}
                      />
                    </div>
                    <div className="formgrid">
                      <input
                        className="datePicker"
                        type="date"
                        value={endDate}
                        onChange={(date) => setEndDate(date.target.value)}
                      />
                    </div>
                    <div className="formgrid">
                      {search ? (
                        <button
                          style={{
                            width: '7rem',
                            backgroundColor: '#DCDCDC',
                          }}
                          className="submit"
                          type="submit"
                          disabled
                        />
                      ) : (
                        <button
                          style={{ width: '7rem' }}
                          className="submit"
                          type="submit"
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="SortContainer">
              <button
                className="Sort-Report-Btn"
                onClick={() => {
                  setSortData((previousData) => !previousData);
                }}
                type="button"
              >
                {btnText}
                {' '}
                {btnText === 'Sort Report' ? (
                  <i className="fas fa-sort" />
                ) : (
                  <i className="fas fa-check-circle" />
                )}
              </button>
            </div>

            <div className="projects-data report-page-data">
              <ol className="projects-list">
                {filteredData.length === 0 ? (
                  <h2
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      marginBottom: '0',
                      color: '#1d1d99',
                    }}
                  >
                    Please Apply Filter
                  </h2>
                ) : outputData.length !== 0 ? (
                  filteredData.map((d, index) => (
                    <li style={{ padding: '10px' }}>
                      <div style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              paddingRight: '50px',
                              width: '100%',
                            }}
                          >
                            <h4>
                              {index + 1}
                              .
                              {d.Project}
                            </h4>

                            <h6>
                              <i className="fas fa-calendar-week" />
                              {' '}
                              {d.Date}
                            </h6>
                            <h6>
                              <i className="far fa-clock" />
                              {` ${d?.TimeSpend} hr`}
                            </h6>
                          </div>
                        </div>

                        <p
                          style={{
                            marginLeft: '22px',
                            marginTop: '15px',
                            marginBottom: '10px',
                          }}
                        >
                          {`${d?.Description?.substring(0, 200)}...`}
                        </p>
                        <h5
                          style={{
                            marginRight: '39px',
                            marginTop: '10px',
                            marginBottom: '5px',
                            textAlign: 'right',
                          }}
                        >
                          <i className="fas fa-user" />
                          {' '}
                          {d?.Emp_Name}
                        </h5>
                      </div>

                      <div className="trash-can">
                        <Tooltip title="Delete" arrow>
                          <i
                            onClick={() => {
                              const id0 = d._id;
                              setId1(id0);
                              setOpen(true);
                            }}
                            className="fa fa-trash"
                          />
                        </Tooltip>
                      </div>

                      <CenterModal
                        isOpen={isOpen}
                        onDismiss={() => setOpen(false)}
                      >
                        <p>Are you sure you want to delete it?</p>

                        <ModalCloseTarget>
                          <div className="button-Wrap">
                            <button
                              className="btn btn-default"
                              onClick={() => {
                                try {
                                  fetch(
                                    `${configdata.REACT_APP_SERVER_URL}delete/${id1}`,
                                    {
                                      method: 'PUT',
                                      headers: {
                                        'Content-Type': 'application/json',
                                        'x-auth-token': token,
                                      },
                                      body: JSON.stringify({ Delete: true }),
                                    },
                                  ).then((res) => res.json());
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 1000);
                                } catch (err) {
                                  return err;
                                }
                              }}
                              type="button"
                            >
                              Yes
                            </button>
                            <button
                              style={{
                                marginLeft: '8px',
                                backgroundColor: '#333',
                              }}
                              className="btn btn-default"
                              type="button"
                            >
                              Close
                            </button>
                          </div>
                        </ModalCloseTarget>
                      </CenterModal>
                    </li>
                  ))
                ) : (
                  <h2
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      marginBottom: '0',
                      color: '#1d1d99',
                    }}
                  >
                    {' '}
                    Data not found
                  </h2>
                )}
              </ol>
              <div className="clearfix" />
              {filterLoader ? <div id="cover-spin" /> : null}

              <div className="projects_export_button">
                {outputData.length === 0 ? null : (
                  <ExportToExcel apiData={outputData} fileName={fileName} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="copy">
          <p> © 2022 | All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default Reports;
