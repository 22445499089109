/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import moment from 'moment';
import Modal from './Modal';

import configdata from '../config.json';

const schema = yup.object().shape({
  ProjectName: yup
    .string()
    .required('Project Name should be required ⚠️')
    .trim('The Project name cannot include  spaces')
    .strict(true),
  ClientName: yup
    .string()
    .required('Client Name should be required ⚠️')
    .trim('The Client name cannot include  spaces')
    .strict(true),
  Date: yup.string().required('Date should be required ⚠️'),
});

function AddProject({ addProject, setAddProject, setLoading }) {
  const [modal, setModal] = useState(false);
  // const cookies = new Cookies();
  // const token= cookies.get('token')
  const token = localStorage.getItem('token');

  const [projectErr, setProjectErr] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const submitForm = async (data) => {
    const projectData = {
      ProjectName: data.ProjectName.toUpperCase(),
      ClientName: data.ClientName,
      Date: moment(data.Date).format('YYYY-MM-DD'),
    };

    const projectName = data.ProjectName.toUpperCase();

    const output = await fetch(
      `${configdata.REACT_APP_SERVER_URL}project/projectexsist`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          projectName,
        }),
      },
    ).then((res) => res.json());
    if (output.status === 'ok') {
      const result = await fetch(
        `${configdata.REACT_APP_SERVER_URL}project/addproject`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          body: JSON.stringify(projectData),
        },
      ).then((res) => res.json());
      if (result.status === 'ok') {
        setAddProject((previousDisplay) => !previousDisplay);
        setModal((previousDisplay) => !previousDisplay);
      } else {
        console.log(result.error);
      }
    } else {
      setProjectErr(output.error);
    }
  };

  return (
    <div>
      {addProject ? (
        <div className="cmn_modal popup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content add-project-modal">
              <div className="modal-body">
                <span
                  onClick={() => {
                    setAddProject((prevDisplay) => !prevDisplay);
                  }}
                  className="close"
                >
                  <FontAwesomeIcon icon={faTimes} color="rgb(168, 156, 156)" />
                </span>
                <form
                  className="employee_details"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="col-md-12">
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Project Name "
                          name="ProjectName"
                          {...register('ProjectName', { required: true })}
                          type="text"
                        />
                        <i className="fa fa-user" />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        {errors.ProjectName?.message}
                        {' '}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Client Name "
                          name="ClientName"
                          type="text"
                          {...register('ClientName', {
                            required: true,
                          })}
                        />
                        <i className="fa fa-user" />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {' '}
                        {errors.ClientName?.message}
                        {' '}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          type="date"
                          id="birthday"
                          name="birthday"
                          {...register('Date', {
                            required: true,
                          })}
                        />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.Date?.message}
                      </p>
                    </div>
                    <div className="addemployee_button">
                      <button type="submit" className="login-sub">
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {projectErr && (
                <h4
                  style={{
                    color: 'red',
                    marginBottom: '2rem',
                    textAlign: 'center',
                    fontWeight: '600',
                  }}
                >
                  {projectErr}
                </h4>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <Modal
        modal={modal}
        setModal={setModal}
        refetchType="Refetch_All_Project"
        setLoading={setLoading}
      />
    </div>
  );
}

export default AddProject;
