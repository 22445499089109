/* eslint-disable import/no-named-as-default */
import React, { useState, useEffect, useContext } from 'react';
import '../css/InvoicePage.css';
import moment, { relativeTimeRounding } from 'moment';
import { Router, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faTrash, faEdit, faDownload, faEye,
} from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import axios from 'axios';
import EditInvoice from '../components/EditInvoice';
import Sidebar from '../components/Sidebar';
import BlogContext from '../utils/Context';
import EditInvoiceModal from '../components/Modal/EditInvoicemodal';
import '../css/Singlepage.css';
import configdata from '../config.json';

function InvoicePage() {
  const { invoiceData, setInvoiceData, getInvoiceData } = useContext(BlogContext);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [deleteEmployee] = useState('true');
  const [modal, setModal] = useState(false);
  const [invoiceStatus, setInvoiceStatus] = useState();

  const [invoiceId, setInvoiceId] = useState('');
  const history = useHistory();
  const token = localStorage.getItem('token');
  useEffect(() => {
    getInvoiceData();
  }, []);

  const DeletedData = {
    Delete: deleteEmployee,
  };
  const deleteInvoice = async () => {
    fetch(
      `${configdata.REACT_APP_SERVER_URL}invoicedata/deleteinvoice/${invoiceId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(DeletedData),
      },
    )
      .then((res) => res.json())
      .then((res) => {
        setInvoiceData(invoiceData.filter((data) => data._id !== res.data._id));
      });
  };
  const downloadPDF = async (item) => {
    axios({
      url: 'http://localhost:4000/downloadpdf', // your url
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      data: item,
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  const invoice0 = invoiceData.slice(-4);

  const newInvoice = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    history.push('/Admin/Invoice/NewInvoice');
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div id="page_content" className="content-main">
          <div className="invoice_list">
            <div className="list_heading">
              <h3 style={{ color: 'gray', fontWeight: '600' }}>
                Recent Invoices
              </h3>
              <div className="new_btn">
                <button type="button" onClick={newInvoice}>
                  New Invoice
                </button>
              </div>
            </div>

            <div className="list_content">
              {invoice0?.map((data) => (
                <div
                  className="card"
                  onClick={() => {
                    localStorage.setItem('invoiceId', data._id);
                    history.push('/Admin/Invoice/SingleInvoice');
                  }}
                >
                  <div className="card_header">
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: '20px' }}
                    />
                  </div>
                  <div className="card_center">
                    <h4 style={{ color: 'gray' }}>Total</h4>
                    <h3 style={{ margin: '10px' }}>
                      <span>{data.Currency}</span>
                      <span style={{ marginLeft: '7px' }}>
                        {data?.TotalAmount}
                      </span>
                    </h3>
                  </div>
                  <div className="card_bottom">
                    <p>
                      <span>#</span>
                      {data?.InvoiceNumber}
                    </p>
                    {moment(data?.createdAt).format('DD-MM-YYYY')}
                    {' '}
                  </div>
                </div>
              ))}
            </div>
            <div className="list_bottom">
              <div className="list_bottom_main_heading">
                <div
                  style={{
                    color: 'gray',
                    borderBottom: ' 2px solid rgb(228, 228, 228)',
                  }}
                >
                  <h4 style={{ fontSize: '20px', color: 'gray' }}>
                    All Invoices
                  </h4>
                </div>
              </div>
              <div className="list_bottom_heading1">
                <div>Invoice Number</div>
                <div>Total</div>
                <div>Date</div>
                <div>Status</div>
                <div>Actions</div>
                <div />
              </div>
              <ul>
                {invoiceData.reverse()?.map((data, i) => (
                  <li>
                    <div className="list_bottom_heading1">
                      <div className="list_bottom_heading_left">
                        <div
                          className="bottom_heading_invoiceNumber"
                          style={{ marginLeft: '2rem', marginBottom: '1rem' }}
                        >
                          {data?.InvoiceNumber}
                        </div>
                        <div
                          style={{ marginLeft: '8rem', marginBottom: '1rem' }}
                        >
                          <span style={{ marginRight: '7px' }}>
                            {data?.Currency}
                          </span>
                          {data?.TotalAmount}
                        </div>
                        <div
                          style={{ marginLeft: '0rem', marginBottom: '1rem' }}
                        >
                          {moment(data?.Dates?.invoiceDate).format(
                            'DD-MM-YYYY',
                          )}
                        </div>
                      </div>
                      <div className="list_bottom_heading_right">
                        <div
                          style={{ marginLeft: '0rem', marginBottom: '1rem' }}
                        >
                          {data?.status}
                          <span style={{ paddingLeft: '10px' }}>
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={() => {
                                setModal(true);
                                setInvoiceStatus(data);
                              }}
                            />
                          </span>
                        </div>

                        <div
                          style={{ marginLeft: '0rem', marginBottom: '1rem' }}
                          onClick={() => {
                            setInvoiceId(data._id);
                            setOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>

                        <div
                          style={{ marginLeft: '0rem', marginBottom: '1rem' }}
                          onClick={() => {
                            localStorage.setItem('invoiceId', data._id);
                            history.push('/Admin/Invoice/EditInvoice');
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </div>
                        <div
                          onClick={() => {
                            localStorage.setItem('invoiceId', data._id);
                            history.push('/Admin/Invoice/SingleInvoice');
                          }}
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </div>
                        <FontAwesomeIcon
                          icon={faDownload}
                          onClick={() => {
                            downloadPDF(data);
                            console.log(data);
                          }}
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <CenterModal isOpen={isOpen} onDismiss={() => setOpen(false)}>
              <p>Are you sure you want to delete it?</p>

              <ModalCloseTarget>
                <div className="button-Wrap">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={deleteInvoice}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    style={{
                      marginLeft: '8px',
                      backgroundColor: '#333',
                    }}
                    className="btn btn-default"
                  >
                    Close
                  </button>
                </div>
              </ModalCloseTarget>
            </CenterModal>
          </div>
        </div>
        {loading ? <div id="cover-spin" /> : null}
        <EditInvoiceModal
          modal={modal}
          setModal={setModal}
          Status={invoiceStatus}
        />
      </div>
    </div>
  );
}

export default InvoicePage;
