/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import configdata from '../../config.json';

function ChangePasswordModal({ passwordModal, setPasswordModal }) {
  const [modal, setModal] = useState(false);
  const [newPasssword, setNewpassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState();
  const [error, setError] = useState('');
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const token = localStorage.getItem('token');

  const empId = localStorage.getItem('empId');

  async function getEmpDetails() {
    await fetch(`${configdata.REACT_APP_SERVER_URL}app/employee/${empId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setEmployeeDetails(data.data));
  }

  useEffect(() => {
    getEmpDetails();
  }, []);

  const editedDetails = {
    FirstName: employeeDetails?.FirstName,
    LastName: employeeDetails?.LastName,
    DOB: employeeDetails?.DOB,
    Password: newPasssword,
    Designation: employeeDetails?.Designation,
    ContactNumber: employeeDetails?.ContactNumber,
  };
  const updatePassword = async () => {
    await fetch(
      `${configdata.REACT_APP_SERVER_URL}app/employee/update/${empId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(editedDetails),
      },
    ).then((res) => res.json());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPasssword === confirmPassword) {
      updatePassword();
      setPasswordModal((prevDisplay) => !prevDisplay);
      setModal((prevDisplay) => !prevDisplay);
    } else {
      setError('Password should be same !');
    }
  };
  return (
    <div>
      {passwordModal ? (
        <div className="cmn_modal popup" id="successModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="tick_success_icon" />
              <span
                className="close"
                onClick={() => setPasswordModal((previousDisplay) => !previousDisplay)}
              >
                <FontAwesomeIcon icon={faTimes} color="rgb(168, 156, 156)" />
              </span>
              <div className="modal_input_container">
                <div className="modal_input0">
                  <input
                    style={{
                      outline: 'none',
                      border: 'none',
                    }}
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Enter New Password"
                    onChange={(e) => setNewpassword(e.target.value)}
                  />
                  <i
                    className="fa fa-lock"
                    style={{ cursor: 'pointer', paddingRight: '5px' }}
                    onClick={togglePasswordVisiblity}
                  />
                </div>
                <div className="modal_input0">
                  <input
                    style={{ outline: 'none', border: 'none' }}
                    type={passwordShown ? 'text' : 'password'}
                    placeholder="Confirm your Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <i
                    className="fa fa-lock"
                    style={{ cursor: 'pointer', paddingRight: '5px' }}
                    onClick={togglePasswordVisiblity}
                  />
                </div>
                <div className="modal_button">
                  <button
                    style={{
                      color: 'white',
                      backgroundColor: '#1d1d99',
                      fontSize: '15px',
                      height: '40px',
                      textAlign: 'center',
                      borderRadius: '5px',
                    }}
                    type="button"
                    className="okbtn"
                    onClick={handleSubmit}
                  >
                    Change Password
                  </button>
                </div>
              </div>
              <p
                style={{
                  color: 'red',
                  textAlign: 'center',
                  marginBottom: '15px',
                  fontWeight: '600',
                }}
              >
                {error}
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <Modal modal={modal} setModal={setModal} />
    </div>
  );
}

export default ChangePasswordModal;
