import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';
import Login from './pages/Login';
import Project from './pages/Project';
import Employee from './pages/Employee';
import Reports from './pages/Reports';
import UserProject from './pages/Users/UserProject';
import ProjectSinglePage from './pages/ProjectSinglePage';
import Invoice from './pages/Invoice';
import ProtectedRoute from './components/ProtectedRoutes/Protected.Route';
import InvoicePage from './pages/InvoicePage';
import './App.css';
import EmployeeSinglePage from './pages/EmployeeSinglePage';
import SingleInvoicePage from './components/SingleInvoicePage';
import EditInvoice from './components/EditInvoice';
import Dashboard from './pages/Dashboard';
import configData from './config.json';

function App() {
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: configData.VAPID_KEY,
      });

      localStorage.setItem('firebasetoken', token);
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <div id="wrapper">
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />

          <ProtectedRoute exact path="/Admin/Project" component={Project} />

          <ProtectedRoute exact path="/Admin/Employee" component={Employee} />
          <ProtectedRoute exact path="/Admin/Reports" component={Reports} />
          <ProtectedRoute exact path="/Admin/Invoice" component={InvoicePage} />
          <ProtectedRoute
            exact
            path="/Admin/Invoice/NewInvoice"
            component={Invoice}
          />
          <ProtectedRoute
            exact
            path="/Admin/Invoice/SingleInvoice"
            component={SingleInvoicePage}
          />
          <ProtectedRoute
            exact
            path="/Admin/Invoice/EditInvoice"
            component={EditInvoice}
          />
          <ProtectedRoute
            exact
            path="/Admin/Project/SinglePage"
            component={ProjectSinglePage}
          />
          <ProtectedRoute exact path="/Admin/Dashboard" component={Dashboard} />
          <ProtectedRoute
            exact
            path="/Admin/Employee/SinglePage"
            component={EmployeeSinglePage}
          />

          <ProtectedRoute exact path="/User/Project" component={UserProject} />
          <ProtectedRoute path="*" component={() => '404 Not Found'} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
