/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import configdata from '../config.json';
import '../css/Login.css';

const cryptojs = require('crypto-js');

const schema = yup.object().shape({
  Email: yup
    .string()
    .trim('Email cannot include  spaces')
    .strict(true)
    .required(
      'Before submitting you need to provide your email in the format: mail@example.com ⚠️',
    ),
  Password: yup
    .string()
    .trim('Password cannot start and end with  spaces')
    .strict(true)
    .required('Before submitting you need to provide your password. ⚠️'),
});
function Login() {
  const [err, setErr] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const token = localStorage.getItem('token');

  const history = useHistory();

  useEffect(() => {
    if (token) {
      const bytes = cryptojs.AES.decrypt(hashrole, encryptKey);
      const hashId = bytes.toString(cryptojs.enc.Utf8);

      if (hashId === 'Admin') {
        history.push('/Admin/Project');
      } else {
        history.push('/User/Project');
      }
    }
  }, [token]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const firebasetoken = localStorage.getItem('firebasetoken');
  let result;

  const hashrole = localStorage.getItem('role');
  const encryptKey = 'dfshghdsgkjfdgkfkjsgskjfksg';
  const submitForm = async (data) => {
    const { Email } = data;
    const { Password } = data;

    result = await fetch(`${configdata.REACT_APP_SERVER_URL}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Email,
        Password,
        firebasetoken,
      }),
    }).then((res) => res.json());

    console.log(result, 'result');
    if (result.status === 'ok') {
      // window?.ReactNativeWebView?.postMessage(result);
      localStorage.setItem('token', result.token);
      await fetch(`${configdata.REACT_APP_SERVER_URL}isUserAuth`, {
        method: 'GET',
        headers: {
          'x-auth-token': result.token,
        },
        body: JSON.stringify(),
      }).then((res) => res.json());
      const response = JSON.stringify(result);
      window?.ReactNativeWebView?.postMessage(response);
      const Emp_Name = `${result.FirstName} ${result.LastName}`;
      const { Emp_Email } = result;
      const id = result._id;

      localStorage.setItem('empId', id);
      localStorage.setItem('Name', Emp_Name);
      localStorage.setItem('Emp_Email', Emp_Email);
      localStorage.setItem('login', 'isloggedin');

      const hashToken = cryptojs.AES.encrypt(
        result.Role,
        encryptKey,
      ).toString();

      localStorage.setItem('role', hashToken);

      const token0 = localStorage.getItem('token');

      if (token0 !== null) {
        if (result.Role) {
          history.push('/Admin/Project');
        } else {
          history.push('/User/Project');
        }
      }
    } else {
      setErr(result.error);
    }
  };

  return (
    <div className="login">
      <div className="login-bottom">
        <h2 className="text-center">
          <span className="logo_img">
            <img src="/images/logo.png" alt="" />
          </span>
        </h2>
        <form className="form" onSubmit={handleSubmit(submitForm)}>
          <div className="col-md-12">
            <div className="input-fieldWrap">
              <div className="login-mail">
                <input
                  placeholder="Email"
                  type="text"
                  name="Email"
                  {...register('Email', { required: true })}
                />
                <i className="fa fa-envelope" />
              </div>
              <p
                style={{
                  color: 'red',
                  marginBottom: '1rem',
                  fontSize: '12px',
                }}
              >
                {errors.Email?.message}
              </p>
            </div>
            <div className="input-fieldWrap">
              <div className="login-mail">
                <input
                  placeholder="Password"
                  name="Password"
                  type={passwordShown ? 'text' : 'password'}
                  {...register('Password', {
                    required: true,
                  })}
                />

                <i
                  className="fa fa-lock"
                  style={{ cursor: 'pointer' }}
                  onClick={togglePasswordVisiblity}
                />
              </div>
              <p
                style={{
                  color: 'red',
                  marginBottom: '1rem',
                  fontSize: '12px',
                }}
              >
                {errors.Password?.message}
              </p>
            </div>
            <div className="login-do">
              <a className="Login_button" href="null">
                <button type="submit" className="login-sub">
                  Login
                </button>
              </a>
            </div>
            <h4
              style={{
                color: 'red',
                marginTop: '2rem',
                textAlign: 'center',
                fontWeight: '600',
              }}
            >
              {err}
            </h4>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
