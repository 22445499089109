/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import BlogContext from '../utils/Context';

function Modal({
  modal, setModal, refetchType, setLoading,
}) {
  const {
    afterFetchEMP,
    setEditPopup,
    projectDataa,
    setUpdatePopUp,
    afterFetch_AdminEmp,
    setBtnLoader,
    SetAdminAddReportPopData,
  } = useContext(BlogContext);
  return (
    <div>
      {modal ? (
        <div className="cmn_modal popup" id="successModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content add-project-success-modal">
              <div className="modal-body">
                <div className="tick_success_icon">
                  <i className="fa fa-thumbs-up" />
                  <p style={{ color: 'black' }}>Successfully Added</p>
                  <button
                    type="button"
                    className="okbtn"
                    onClick={() => {
                      setModal((prevDisplay) => !prevDisplay);
                      if (refetchType === 'Refetch_Emp_Report') {
                        afterFetchEMP();
                      } else if (refetchType === 'Refetch_All_Report') {
                        afterFetch_AdminEmp();
                      } else if (refetchType === 'Refetch_All_Project') {
                        projectDataa(setLoading);
                      }
                      setEditPopup(false);
                      setUpdatePopUp(false);
                      setBtnLoader(true);
                      SetAdminAddReportPopData((previousData) => !previousData);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Modal;
