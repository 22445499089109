/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsFillArrowLeftSquareFill } from 'react-icons/bs';
import DataContext from '../utils/DataContext';
import '../css/AddSenderDetail.css';
import configdata from '../config.json';

function AddSenderDetails({
  addSenderDetail, setAddSenderDetail, text1, text2, button,
}) {
  const {
    senderDetails, setSenderDetails, recieverDetails, setRecieverDetails,
  } = useContext(DataContext);

  const [senderloader, setSenderLoader] = useState(false);
  const [recieverloader, setRecieverLoader] = useState(false);
  const token = localStorage.getItem('token');

  const handleChange = (e) => {
    if (text1 === 'Company/Client Name') {
      setSenderDetails({
        ...senderDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setRecieverDetails({
        ...recieverDetails,
        [e.target.name]: e.target.value,
      });
    }
  };
  const AddSenderInfo = async () => {
    setSenderLoader(true);
    setTimeout(() => {
      setSenderLoader(false);
    }, 2000);
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}senderinfo/addinfo`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,

        },
        body: JSON.stringify(
          senderDetails,
        ),
      },
    ).then((res) => res.json());
    if (result.status === 'ok') {
      console.log(result);
    }
  };
  const AddRecieverInfo = async () => {
    setRecieverLoader(true);
    setTimeout(() => {
      setRecieverLoader(false);
    }, 2000);
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}recieverinfo/addinfo`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,

        },
        body: JSON.stringify(
          recieverDetails,
        ),
      },
    ).then((res) => res.json());
    if (result.status === 'ok') {
      console.log(result);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (text1 === 'Company/Client Name') {
      AddSenderInfo();
      setAddSenderDetail((prevDisplay) => !prevDisplay);
    } else {
      AddRecieverInfo();
      setAddSenderDetail((prevDisplay) => !prevDisplay);
    }
  };
  const history = useHistory();
  return (
    <div>
      {addSenderDetail ? (
        <div className="cmn_modal popup">
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: '600px' }}
          >
            <div className="modal-content">
              <div className="modal-body">
                <span>
                  <BsFillArrowLeftSquareFill 
                    onClick={() => history.goBack()}
                    className="backButton"
                  />
                </span>
                <span
                  onClick={() => {
                    setAddSenderDetail((prevDisplay) => !prevDisplay);
                  }}
                  className="close"
                >
                  <FontAwesomeIcon icon={faTimes} color="rgb(168, 156, 156)" />
                </span>

                <form
                  className="employee_details"
                  onSubmit={handleSubmit}
                >
                  <div className="col-md-12">
                    <div className="input-fieldwrap">
                      <div className="form_field1">
                        <p>
                          {text1}
                          {' '}
                        </p>
                        <input type="text" name="CompanyName" onChange={handleChange} />
                      </div>
                    </div>

                    <div className="input-fieldwrap">
                      <div className="form_field">
                        <p>First Name</p>
                        <input type="text" name="FirstName" onChange={handleChange} />
                      </div>
                      <div
                        className="form_field"
                        style={{ marginLeft: '2rem' }}
                      >
                        <p>Last Name</p>
                        <input type="text" name="LastName" onChange={handleChange} />
                      </div>
                    </div>
                    <div className="input-fieldwrap">
                      <div className="form_field">
                        <p>Email</p>
                        <input type="text" name="Email" onChange={handleChange} />
                      </div>
                      <div
                        className="form_field"
                        style={{ marginLeft: '2rem' }}
                      >
                        <p>Country</p>
                        <input type="text" name="Country" onChange={handleChange} />
                      </div>
                    </div>
                    <div className="input-fieldwrap">
                      <div className="form_field">
                        <p>Address Line 1</p>
                        <input type="text" name="Address1" onChange={handleChange} />
                      </div>
                      <div
                        className="form_field"
                        style={{ marginLeft: '2rem' }}
                      >
                        <p>Address Line 2</p>
                        <input type="text" name="Address2" onChange={handleChange} />
                      </div>
                    </div>
                    <div className="input-fieldwrap">
                      <div className="form_field">
                        <p>Phone</p>
                        <input type="number" name="Phone" onChange={handleChange} style={{ width: '250px', border: '2px solid rgb(172,165,165' }} />
                      </div>
                      <div
                        className="form_field"
                        style={{ marginLeft: '2rem' }}
                      >
                        <p>{text2}</p>
                        <input type="text" />
                      </div>
                    </div>
                    <div className="form_field_submit">
                      <button type="submit">{button}</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {senderloader ? <div id="cover-spin" /> : null}
      {recieverloader ? <div id="cover-spin" /> : null}
    </div>
  );
}

export default AddSenderDetails;
