import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import SuccessModel from './SuccessModel';
import BlogContext from '../utils/Context';
import useFetch from '../hooks/useFetch';
import styles from '../css/EditInvoiceUserDetail.module.css';
import configdata from '../config.json';

const inputFields = [
  {
    id: 0,
    name: 'CompanyName',
    errorMessage: 'Company name is required',
  },
  {
    id: 1,
    name: 'FirstName',
    errorMessage: 'first name is required',
  },
  {
    id: 2,
    name: 'LastName',
    errorMessage: 'last name is required',
  },
  {
    id: 3,
    name: 'Email',
    errorMessage: 'email is required',
  },
  {
    id: 4,
    name: 'Address1',
    errorMessage: 'address 1 is required',
  },
  {
    id: 5,
    name: 'Address2',
    errorMessage: 'address2 is required',
  },
  {
    id: 6,
    name: 'Phone',
    errorMessage: 'Phone number is required',
  },
  {
    id: 7,
    name: 'Website',
    errorMessage: 'website is required',
  },
];

function EditInvoiceUserDetail({ id, mode }) {
  const [greet, setGreet] = useState(false);
  const { setEditInvoiceUserDetail } = useContext(BlogContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const { apiData, isLoading, serverError } = useFetch(
    `${configdata.REACT_APP_SERVER_URL}${mode}/${id}`,
  );
  const [Invoicedata, setInvoiceData] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    setInvoiceData(apiData?.data);
  }, [apiData]);

  const onSubmit = async (data) => {
    const token = localStorage.getItem('token');
    await fetch(
      `${configdata.REACT_APP_SERVER_URL}${mode}/${Invoicedata._id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(data),
      },
    )
      .then((res) => res.json())
      .then(() => {
        setShowSuccess(false);
        setGreet(true);
      });
    // .then(() => setEditInvoiceUserDetail({ shouldOpen: false, id: null, mode: '' }));
  };

  return (
    <div className={styles.EditInvoiceUserDetail}>
      <div className={styles.box_container}>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setEditInvoiceUserDetail({ shouldOpen: false, id: null, mode: '' })}
          style={{ float: 'right', cursor: 'pointer', fontSize: '25px' }}
          size="1x"
        />
        {Invoicedata && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {inputFields.map((d) => (
              <div>
                <input
                  {...register(d.name, {
                    required: d.name !== 'Website',
                  })}
                  placeholder={d.name}
                  defaultValue={Invoicedata[d.name]}
                />
                {errors[d.name]?.type === 'required' && (
                  <span style={{ color: 'red', fontSize: '12px' }}>
                    {d.errorMessage}
                  </span>
                )}
              </div>
            ))}
            {showSuccess && (
              <SuccessModel>
                <div>
                  <h1>Please confirm to continue.</h1>
                </div>
                <div className={styles.Model__btn__container}>
                  <button
                    type="button"
                    style={{ background: '#e74c3c', border: 'none' }}
                    onClick={() => setShowSuccess(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    style={{ background: '#2ecc71', border: 'none' }}
                  >
                    Confirm
                  </button>
                </div>
              </SuccessModel>
            )}
            <button
              type="button"
              onClick={() => setShowSuccess(true)}
              style={{
                background: greet && '#2ecc71',
                border: greet && 'none',
              }}
            >
              {greet ? 'Updated Successfully' : 'Edit Details'}
            </button>
          </form>
        )}
        {isLoading && <div>Loading...</div>}
      </div>
    </div>
  );
}

export default EditInvoiceUserDetail;
