import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import DashboardTaskDetails from '../components/dashboardTaskDetails';
import Sidebar from '../components/Sidebar';
import { Year, Month } from '../utils/timeData';
import '../css/dashboard.css';
import configdata from '../config.json';

function Dashboard() {
  const [reportList, setReportList] = useState([]);

  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const history = useHistory();
  const token = localStorage.getItem('token');
  const { apiData, isLoading, serverError } = useFetch(
    `${configdata.REACT_APP_SERVER_URL}getlast2dayreport`,
  );
  const [cardData, setCardData] = useState(null);
  const [cardError, setCardError] = useState(false);

  useEffect(() => {
    if (apiData?.status === 'ok') {
      setCardData(apiData?.data);
      return;
    }
    setCardError(true);
  }, [apiData]);

  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const startingMonth = month?.value;
  const startingYear = year?.value;

  const STARTDATE = `${startingYear}-${startingMonth}-01`;

  const ENDDATE = `${startingYear}-${startingMonth}-30`;

  const CurrStartDate = moment(startDate).format('YYYY-MM-DD');
  const CurrEndDate = moment(endDate).format('YYYY-MM-DD');

  const SelectedStartDate = startingMonth !== undefined ? STARTDATE : CurrStartDate;
  const SelectedEndDate = startingMonth !== undefined ? ENDDATE : CurrEndDate;

  const data = {
    StartDate: SelectedStartDate,
    EndDate: SelectedEndDate,
  };

  const getProjectDetails = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}searchprojectdata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data3) => {
        const data0 = data3.filter((data4) => data4.Delete !== 'true');
        setReportList(data0);
      });
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const sameData = reportList.filter(
    (ele, ind) => ind === reportList.findIndex((elem) => elem.Project === ele.Project),
  );

  const Time = reportList
    .map((data2) => data2.TimeSpend)
    .reduce((prev, curr) => prev + curr, 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    getProjectDetails();
  };
  const projectId = 0;
  return (
    <>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div className="content-main">
          <div className="content-all">
            <div className="projects_complete_detials">
              <div className="project-filters report-page-filters">
                <form onClick={handleSubmit}>
                  <div
                    className="filter-form"
                    style={{ gridTemplateColumns: '1.5fr 1.5fr .5fr' }}
                  >
                    <div className="formgrid">
                      <Select
                        placeholder="Select Year"
                        value={year}
                        onChange={setYear}
                        options={Year}
                      />
                    </div>
                    <div className="formgrid">
                      <Select
                        placeholder="Select Month"
                        value={month}
                        onChange={setMonth}
                        options={Month}
                      />
                    </div>
                    <div className="formgrid">
                      <button className="submit" type="submit" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="projects-data report-page-data"
            >
              <div className="content_top">
                <div className="cards">
                  <h2>Active Projects</h2>
                  <ol className="project_list">
                    {sameData.map((data1) => (
                      <li>
                        <div
                          onClick={() => {
                            localStorage.setItem('projectId', projectId);
                            const projectName = data1.Project;
                            localStorage.setItem('projectName', projectName);
                            history.push('/Admin/Project/SinglePage');
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {data1.Project}
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
                <div className="time_card">
                  <h2>Total Time Spent</h2>

                  <div className="card_content">
                    <h4>
                      {Time}
                      hrs
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="DashboardTaskDetails_container">
              <DashboardTaskDetails
                venue="Today"
                dayData={cardData?.today}
                cardError={cardError}
              />
              <DashboardTaskDetails
                venue="yesterday"
                dayData={cardData?.yesterday}
                cardError={cardError}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
