import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { BlogProvider } from './utils/Context';
import { DataContextProvider } from './utils/DataContext';

ReactDOM.render(
  <BlogProvider>
    <DataContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </DataContextProvider>
  </BlogProvider>,
  document.getElementById('root'),
);
