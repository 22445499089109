import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Select from 'react-select';
import moment from 'moment';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import BlogContext from '../utils/Context';
import AddReport from '../components/AddReport';
import EditReportPopup from '../components/EditReportPopup';
import Sidebar from '../components/Sidebar';
import DeletedReport from '../utils/deleteReport';
import 'react-spring-modal/styles.css';
import '../css/Singlepage.css';
import configdata from '../config.json';

function EmployeeSinglePage() {
  const location = useLocation();

  const {
    getData,
    empData,
    setEmpData,
    editPopup,
    setEditPopup,
    setemp_Email,
    employeeData,
  } = useContext(BlogContext);
  const [deleteReport] = useState('true');
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [reportid1, setReportId1] = useState('');
  const [popupInfo, setPopupInfo] = useState([]);
  const [searchItem, setSearchItem] = useState([]);
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [filterData, setFilterData] = useState([]);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [sortData, setSortData] = useState(false);
  const [btnText, setBtnText] = useState('Sort Report');
  const [Delete, setDelete] = useState(false);
  const [isfilterData, setIsFilterData] = useState(false);
  const [totalRecord, setTotalRecord] = useState();
  const [state, setState] = useState({
    items: Array.from({ length: 30 }),
    hasMore: true,
  });

  const userEmail = localStorage.getItem('employeeEmail');

  setemp_Email(userEmail);
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (sortData) {
      const hello = filterData.sort(
        (a, b) => new Date(a.Date) - new Date(b.Date),
      );
      setFilterData(hello);
      setBtnText('Sorted');
    } else {
      const hello = filterData.sort(
        (a, b) => new Date(b.Date) - new Date(a.Date),
      );
      setFilterData(hello);
      setBtnText('Sort Report');
    }
  }, [sortData]);

  useEffect(() => {
    if (sortData) {
      const hello = empData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      setEmpData(hello);
      setBtnText('Sorted');
    }
  }, [sortData]);

  // useEffect(() => {
  //   if (!sortData) {
  //     getData(setEmpData, userEmail, 0, 10);
  //     setBtnText('Sort Report');
  //   }
  // }, [sortData]);

  useEffect(() => {
    getData(setEmpData, userEmail, 0, 10).then((data) => {
      setTotalRecord(data.total);
    });
  }, []);

  useEffect(() => {
    if (Delete) {
      getData(setEmpData, userEmail, 0, 10);
    }
  }, [Delete]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 3000);
  }, [loading]);

  const pp = empData.filter(
    (ele, ind) => ind === empData.findIndex((elem) => elem.Emp_Name === ele.Emp_Name),
  );
  const dd = empData.filter(
    (ele, ind) => ind === empData.findIndex((elem) => elem.Project === ele.Project),
  );
  const ProjectOptions = dd.map((d) => ({
    value: d.Project,
    label: d.Project,
  }));
  const fetchMoreData = () => {
    if (state.items.length >= 500) {
      setState({ ...state, hasMore: false });
      return;
    }

    setTimeout(() => {
      setState({
        items: state.items.concat(Array.from({ length: 30 })),
      });
    }, 1000);
  };
  const DeletedData = {
    Delete: deleteReport,
  };

  const array = empData
    .filter((data) => {
      const del = data.Delete;
      if (del === 'false') {
        return data;
      }
      return false;
    })
    .map((d) => d.TimeSpend);

  const EmployeeName = userEmail;

  const ProjectName = searchItem.map((d) => d.value);

  const searchedData = {
    Name: EmployeeName,
    Project: ProjectName,
    StartDate: moment(searchStartDate).format('YYYY-MM-DD'),
    EndDate: moment(searchEndDate).format('YYYY-MM-DD'),
  };

  const filteredData = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(searchedData),
    })
      .then(async (res) => res.json())
      .then((data) => {
        setFilterData(data.result, console.log());
      });
  };
  useEffect(() => {
    if (isfilterData) {
      filteredData();
    }
  }, [isfilterData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    filteredData();
  };

  const filterArray = filterData
    .filter((data) => {
      const del = data.Delete;
      if (del === 'false') {
        return data;
      }
      return false;
    })
    .map((d) => d.TimeSpend);
  function sum(arr) {
    let s = 0;
    for (let i = 0; i < arr.length; i += 1) {
      s += arr[i];
    }
    return s;
  }
  const outputSum = sum(array);
  const filterSum = sum(filterArray);

  return (
    <div>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div className="content-main">
          <div className="content-all">
            <div className="projects_complete_detials">
              <div
                className="client-details"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '25px',
                }}
              >
                <div>
                  <h2
                    style={{
                      color: '#1d1d99',
                      marginLeft: '1rem',
                      fontWeight: '600',
                    }}
                  >
                    {location?.state?.empName}
                    {' '}
                  </h2>
                </div>
                <div>
                  <button
                    type="button"
                    className="Add-Report-Btn"
                    onClick={() => {
                      setUpdatePopUp(true);
                    }}
                  >
                    + Add Report
                  </button>
                </div>
              </div>
              <AddReport
                editPopup={updatePopUp}
                setEditPopup={setUpdatePopUp}
                popupInfo={pp[0]}
              />
              <div className="project-filters project-page-filters project-singlePage">
                <form onSubmit={handleSubmit}>
                  <div className="filter-form">
                    <div className="formgrid">
                      <input
                        className="header_datepicker"
                        placeholderText="Start Date"
                        type="date"
                        value={searchStartDate}
                        onChange={(date) => {
                          setSearchStartDate(date.target.value);
                        }}
                      />
                    </div>
                    <div className="formgrid">
                      <input
                        className="header_datepicker"
                        placeholderText="Start Date"
                        type="date"
                        value={searchEndDate}
                        onChange={(date) => {
                          setSearchEndDate(date.target.value);
                        }}
                      />
                    </div>
                    <div className="formgrid">
                      <Select
                        placeholder="Project Name"
                        value={searchItem}
                        onChange={setSearchItem}
                        options={ProjectOptions}
                        isMulti
                      />
                    </div>
                    <div className="formgrid">
                      <button type="submit" className="submit search-btn" />
                    </div>
                    <p style={{ marginLeft: '1rem', fontWeight: '600' }}>
                      <i
                        className="fa fa-clock-o"
                        aria-hidden="true"
                        style={{ marginRight: '6px' }}
                      />
                      Total Hours :
                      <span style={{ color: '#1d1d99' }}>
                        {' '}
                        {filterData.length === 0 ? outputSum : filterSum}
                        hr
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>

            <div className="SortContainer">
              <button
                type="button"
                className="Sort-Report-Btn"
                onClick={() => {
                  setSortData((previousData) => !previousData);
                }}
              >
                {btnText}
                {' '}
                {btnText === 'Sort Report' ? (
                  <i className="fas fa-sort" />
                ) : (
                  <i className="fas fa-check-circle" />
                )}
              </button>
            </div>

            <div
              id="scrollableDiv"
              className="task_list"
              style={{
                marginTop: '1rem',
                minHeight: '77vh',
                height: '500px',
                overflow: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <ul className="listing">
                {filterData.length === 0 ? (
                  loading ? (
                    <InfiniteScroll
                      dataLength={empData.length}
                      next={() => {
                        getData(setEmpData, userEmail, empData?.length, 10);
                      }}
                      hasMore={empData.length < totalRecord}
                      scrollableTarget="scrollableDiv"
                      loader={
                        empData.filter((data) => {
                          const del = data.Delete;
                          if (del === 'false') {
                            return data;
                          }
                          return false;
                        }).length !== 0 ? (
                          <h4>Loading...</h4>
                          ) : (
                            <b
                              style={{
                                color: '#1d1d99',
                                position: 'absolute',
                                top: '35%',
                                left: '53%',
                              }}
                            >
                              No data found
                            </b>
                          )
                      }
                      endMessage={(
                        <p style={{ textAlign: 'center' }}>
                          {empData.length !== 0 ? (
                            <b style={{ color: '#1d1d99' }}>
                              Yay! You have seen it all
                            </b>
                          ) : (
                            <b
                              style={{
                                color: '#1d1d99',
                                position: 'absolute',
                                top: '35%',
                                left: '53%',
                                fontSize: '1rem',
                              }}
                            >
                              No data found
                            </b>
                          )}
                        </p>
                      )}
                    >
                      {empData
                        .filter((data) => {
                          const del = data.Delete;
                          if (del === 'false') {
                            return data;
                          }
                          return false;
                        })
                        .map((data) => (
                          <li className="pzge_maincontent">
                            <div className="left">
                              <h2>
                                {moment(data.Date).format('DD/MM/YYYY')}
                                <span>
                                  <h2>
                                    {' '}
                                    {data.Project}
                                  </h2>
                                </span>
                              </h2>
                              <p>{data.Description}</p>
                            </div>
                            <div className="right">
                              <Tooltip title="Time spent" arrow>
                                <p>
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                    style={{ marginRight: '6px' }}
                                  />
                                  Time Spent :
                                  {' '}
                                  {data.TimeSpend}
                                  hr
                                </p>
                              </Tooltip>
                              <p style={{ marginTop: '8px' }}>
                                <span style={{ marginBottom: '8px' }}>
                                  <Tooltip title="Edit" placement="left">
                                    <i
                                      className="fa fa-edit"
                                      onClick={() => {
                                        setPopupInfo(data);

                                        setEditPopup(
                                          (previousDisplay) => !previousDisplay,
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              </p>
                              <p style={{ marginTop: '8px' }}>
                                <span style={{ marginBottom: '8px' }}>
                                  <Tooltip title="Delete" placement="left">
                                    <i
                                      className="fa fa-trash"
                                      onClick={() => {
                                        const id0 = data._id;
                                        setReportId1(id0);
                                        setOpen(true);
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                                <CenterModal
                                  isOpen={isOpen}
                                  onDismiss={() => setOpen(false)}
                                >
                                  <p>Are you sure you want to delete it?</p>
                                  <ModalCloseTarget>
                                    <div className="button-Wrap">
                                      <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={async () => {
                                          setLoading(true);
                                          setOpen(false);
                                          await DeletedReport(
                                            reportid1,
                                            DeletedData,
                                            () => {
                                              setDelete(true);
                                            },
                                          );
                                          setTimeout(() => {
                                            setDelete(false);
                                          }, 1000);
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        style={{
                                          marginLeft: '8px',
                                          backgroundColor: '#333',
                                        }}
                                        className="btn btn-default"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </ModalCloseTarget>
                                </CenterModal>
                              </p>
                            </div>
                          </li>
                        ))}
                    </InfiniteScroll>
                  ) : (
                    <div id="cover-spin" />
                  )
                ) : (
                  filterData
                    .filter((d) => {
                      const del = d.Delete;
                      if (del === 'false') {
                        return d;
                      }
                      return false;
                    })
                    .map((d) => (
                      <li className="pzge_maincontent">
                        <div className="left" style={{ cursor: 'pointer' }}>
                          <h2>
                            {moment(d.Date).format('DD/MM/YYYY')}
                            <span>
                              <h2>
                                {' '}
                                {d.Project}
                              </h2>
                            </span>
                          </h2>

                          <p>{d.Description.substring(0, 500)}</p>
                        </div>

                        <div className="right">
                          <p>
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                              style={{ marginRight: '6px' }}
                            />
                            Time Spent :
                            {' '}
                            {d.TimeSpend}
                            hr
                          </p>
                          <p>
                            <span>
                              <span>
                                <i
                                  className="fa fa-edit"
                                  onClick={() => {
                                    setPopupInfo(d);

                                    setEditPopup(
                                      (previousDisplay) => !previousDisplay,
                                    );
                                  }}
                                />
                              </span>
                            </span>
                            <span>
                              <span>
                                <i
                                  onClick={() => {
                                    const id0 = d._id;
                                    setReportId1(id0);
                                    setOpen(true);
                                  }}
                                  className="fa fa-trash"
                                />
                              </span>
                            </span>
                            <CenterModal
                              isOpen={isOpen}
                              onDismiss={() => setOpen(false)}
                            >
                              <p>Are you sure you want to delete it?</p>

                              <ModalCloseTarget>
                                <div className="button-Wrap">
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={async () => {
                                      setLoading(true);
                                      setOpen(false);
                                      await DeletedReport(
                                        reportid1,
                                        DeletedData,
                                        () => {
                                          setIsFilterData(true);
                                        },
                                      );
                                      setTimeout(() => {
                                        setIsFilterData(false);
                                      }, 1000);
                                    }}
                                  >
                                    Yes
                                  </button>
                                  <button
                                    type="button"
                                    style={{
                                      marginLeft: '8px',
                                      backgroundColor: '#333',
                                    }}
                                    className="btn btn-default"
                                  >
                                    Close
                                  </button>
                                </div>
                              </ModalCloseTarget>
                            </CenterModal>
                          </p>
                        </div>
                      </li>
                    ))
                )}
              </ul>
            </div>
            <div className="popup-Wrap">
              <EditReportPopup
                editPopup={editPopup}
                setEditPopup={setEditPopup}
                popupInfo={popupInfo}
              />
            </div>
          </div>
        </div>
        <div className="copy">
          <p> © 2022 | All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default EmployeeSinglePage;
