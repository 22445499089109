/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import configdata from '../config.json';

const BlogContext = React.createContext();

export function BlogProvider({ children }) {
  const [editInvoiceUserDetail, setEditInvoiceUserDetail] = useState({
    shouldOpen: false,
    id: null,
    mode: '',
  });
  const [empReport, setEmpReport] = useState([]);

  const [Emp_email, setemp_Email] = useState('');
  const [editPopup, setEditPopup] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [updatePopUp, setUpdatePopUp] = useState(false);
  const [projects, setProjects] = useState([]);
  const [AdminAddReportPopData, SetAdminAddReportPopData] = useState(false);
  const [empImg, setempImg] = useState(null);
  const [currentEmp, setCurrentEmp] = useState(null);
  const [uploadPopup, setUploadPopup] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceId] = useState('');
  const [projectReport, setProjectReport] = useState([]);
  const [userImage, setUserImage] = useState();
  const empId = localStorage.getItem('empId');
  const token = localStorage.getItem('token');

  const projectDataa = async (setLoading) => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}project`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    })
      .then((res) => res.json())
      .then((data) => setProjects(data))
      .catch((error) => error);

    setLoading(true);
  };

  const afterFetchEMP = async () => {
    await fetch(
      `${configdata.REACT_APP_SERVER_URL}search/empreport/${Emp_email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      },
    )
      .then((res) => res.json())
      .then((data) => setEmpReport(data, console.log()));
  };

  const afterFetch_AdminEmp = async () => {
    await fetch(
      `${configdata.REACT_APP_SERVER_URL}search/empreport/${Emp_email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      },
    )
      .then((res) => res.json())
      .then((data) => setEmpData(data));
  };

  const reFetchAfterUpdate = async (editedData, popupInfoId, setModal) => {
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}updateReport/${popupInfoId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(editedData),
      },
    ).then((res) => res.json());
    if (result.status === 'ok') {
      setModal((previousDisplay) => !previousDisplay);
    } else {
      console.log(result.error);
    }
  };

  const getData = async (setempData, userEmail, offset, limit, callback) => {
    const Email = userEmail;
    const Offset = offset;
    const Limit = limit;
    const response = await fetch(
      `${configdata.REACT_APP_SERVER_URL}search/empreport`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({ Email, Offset, Limit }),
      },
    );
    const data = await response.json();
    // ([...data.result]);

    setEmpData((prev) => [...prev, ...data.result]);
  
    if (callback) {
      callback();
    }
    return data;
  };

  const fetchEmp = async (empid) => {
    let Emp;
    await fetch(`${configdata.REACT_APP_SERVER_URL}app/employee/${empid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        Emp = data.data;
        setCurrentEmp(data.data);
      });

    return Emp;
  };
  const getInvoiceData = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}invoicedata/getinvoice`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setInvoiceData(data.data));
  };
  const projectReportData = async (name) => {
    const projectReportarr = [];
    await fetch(`${configdata.REACT_APP_SERVER_URL}GetProjectReport`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        data.forEach((i) => {
          if (name === i.Project) {
            const employeeReport = i;

            projectReportarr.push(employeeReport);
            setProjectReport(projectReportarr);
          }
        });
      });
  };
  const getUserImage = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}img/${empId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        setUserImage(data);
      });
  };

  return (
    <BlogContext.Provider
      value={{
        btnLoader,
        setBtnLoader,
        afterFetchEMP,
        Emp_email,
        setemp_Email,
        empReport,
        reFetchAfterUpdate,
        setEmpReport,
        editPopup,
        setEditPopup,
        getData,
        empData,
        setEmpData,
        updatePopUp,
        setUpdatePopUp,
        afterFetch_AdminEmp,
        projects,
        setProjects,
        projectDataa,
        AdminAddReportPopData,
        SetAdminAddReportPopData,
        fetchEmp,
        empImg,
        setempImg,
        currentEmp,
        setCurrentEmp,
        uploadPopup,
        setUploadPopup,
        getInvoiceData,
        setInvoiceData,
        invoiceData,
        invoiceId,
        projectReportData,
        projectReport,
        setProjectReport,
        getUserImage,
        userImage,
        setUserImage,
        editInvoiceUserDetail,
        setEditInvoiceUserDetail,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
}

export default BlogContext;
