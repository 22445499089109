import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import BlogContext from '../utils/Context';
import Modal from './Modal';
import '../css/SinglepagePopup.css';
import configdata from '../config.json';

function AddReport({ editPopup, setEditPopup, popupInfo }) {
  const { AdminAddReportPopData } = useContext(BlogContext);

  const [projectData, setProjectData] = useState([]);
  const [modal, setModal] = useState(false);

  const [reportData, setReportData] = useState({
    Date: '',
    Project: '',
    Description: '',
    TimeSpend: '',
    editable: false,
  });

  useEffect(() => {
    setReportData((previousData) => ({
      ...previousData,
      Date: '',
      Project: '',
      Description: '',
      TimeSpend: '',
      editable: false,
    }));
  }, [AdminAddReportPopData]);

  const token = localStorage.getItem('token');

  const projectdata = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}project/`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        const result = data.filter((d) => d.Delete === 'false');
        setProjectData(result);
      });
  };

  useEffect(() => {
    projectdata();
  }, [editPopup]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editedData = {
      Emp_Name: popupInfo.Emp_Name,
      Emp_Email: popupInfo.Emp_Email,
      Date: moment(reportData.Date).format('YYYY-MM-DD'),
      Project: reportData.Project,
      Description: reportData.Description,
      TimeSpend: reportData.TimeSpend,
    };
    const result = await fetch(`${configdata.REACT_APP_SERVER_URL}addreport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(editedData),
    }).then((res) => res.json());
    if (result.status === 'ok') {
      setModal((previousDisplay) => !previousDisplay);
    } else {
      console.log(result.error);
    }
  };

  return (
    <div>
      {editPopup ? (
        <div className="cmn_modal popup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <span
                  className="close"
                  onClick={() => {
                    setEditPopup((previousDisplay) => !previousDisplay);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
                <form className="employee_details" onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          type="date"
                          id="birthday"
                          name="birthday"
                          value={reportData.editable && reportData.Date}
                          onChange={(e) => {
                            setReportData((previousData) => ({
                              ...previousData,
                              editable: true,
                              Date: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <select
                          placeholder="Select Project "
                          name="SelectProject"
                          value={reportData.editable && reportData.Project}
                          onChange={(e) => {
                            setReportData((previousData) => ({
                              ...previousData,
                              editable: true,
                              Project: e.target.value,
                            }));
                          }}
                          className="select-btn"
                        >
                          <option value="null" disbled selected>
                            Select Project
                          </option>
                          {projectData.map((project, _id) => (
                            <option value={project.ProjectName}>
                              {project.ProjectName}
                            </option>
                          ))}
                          {' '}
                        </select>
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <textarea
                          className="textarea-field"
                          placeholder="Add task description here"
                          name="Description"
                          type="text"
                          value={reportData.editable && reportData.Description}
                          onChange={
                            reportData.editable
                              ? (e) => {
                                setReportData((prevData) => ({
                                  ...prevData,
                                  Description: e.target.value,
                                }));
                              }
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Time spend in hours"
                          type="number"
                          min="0"
                          step="0.5"
                          name="TimeSpend"
                          value={reportData.editable && reportData.TimeSpend}
                          onChange={
                            reportData.editable
                              ? (e) => {
                                setReportData((prevData) => ({
                                  ...prevData,
                                  TimeSpend: e.target.value,
                                }));
                              }
                              : null
                          }
                        />
                      </div>
                    </div>
                    <div className="formgrid">
                      <button
                        style={{ color: 'white' }}
                        type="submit"
                        className="login-sub adddetail-user"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
                <Modal
                  modal={modal}
                  setModal={setModal}
                  refetchType="Refetch_All_Report"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default AddReport;
