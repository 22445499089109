/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faUser,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import SuccessModel from './SuccessModel';
import Sidebar from './Sidebar';
import EditInvoiceUserDetail from './EditInvoiceUserDetail';
import deleteInvoiceUser from '../utils/deleteInvoiceUser';
import BlogContext from '../utils/Context';
import '../css/EditInvoice.css';
import '../css/Singlepage.css';
import styles from '../css/EditInvoiceUserDetail.module.css';
import configdata from '../config.json';

const options = [
  { value: 'Invoice', label: 'Invoice' },
  { value: 'Tax Invoice', label: 'Tax Invoice' },
  { value: 'Commercial Invoice', label: 'Commercial Invoice' },
  { value: 'Proforma Invoice', label: 'Proforma Invoice' },
  { value: 'Bill', label: 'Bill' },
  { value: 'Receipt', label: 'Receipt' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Quote', label: 'Quote' },
  { value: 'Estimate', label: 'Estimate' },
];

const SuccesRawData = {
  should: false,
  id: '',
  type: '',
  callback: null,
};

function EditInvoice(props) {
  // console.log(props, 'propsprops');
  const [showSuccess, setShowSuccess] = useState({
    ...SuccesRawData,
  });
  const { editInvoiceUserDetail, setEditInvoiceUserDetail } = useContext(BlogContext);

  const [invoiceData, setInvoiceData] = useState();
  const [display, setDisplay] = useState(false);

  const [recieverDisplay, setRecieverDisplay] = useState(false);
  const [invoiceType, setInvoiceType] = useState({
    InvoiceType: '',
  });
  const [currency, setCurrency] = useState({
    currency: '',
  });

  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceDueDate, setInvoiceDueDate] = useState('');
  const [senderInfoData, setSenderInfoData] = useState([]);
  const [recieverInfoData, setRecieverInfoData] = useState([]);
  const [senderResult, setSenderResult] = useState([]);
  const [recieverResult, setRecieverResult] = useState([]);
  const [itemFields, setItemFields] = useState();
  const [invoiceNumber, setInvoiceNumber] = useState();
  const [loader, setLoader] = useState(false);
  const InvoiceDates = {
    invoiceDate:
      invoiceDate === '' ? invoiceData?.Dates?.invoiceDate : invoiceDate,
    invoiceDueDate:
      invoiceDueDate === ''
        ? invoiceData?.Dates?.invoiceDueDate
        : invoiceDueDate,
  };

  const token = localStorage.getItem('token');
  const history = useHistory();

  const InvoiceId = localStorage.getItem('invoiceId');

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...itemFields];

    list[index][name] = value;
    const item = list.map((items, i) => {
      const array = list[i];
      array.totalValue = items.HRS * items.Rate;
      return array;
    });
    setItemFields(item);
  };
  useEffect(() => {
    setInvoiceData((prevData) => ({
      ...prevData,
      InvoiceType: invoiceType.InvoiceType,
    }));
  }, [invoiceType]);

  useEffect(() => {
    setInvoiceData((prevousData) => ({
      ...prevousData,
      Currency: currency.currency,
    }));
  }, [currency]);
  useEffect(() => {
    setInvoiceData((prevData) => ({
      ...prevData,
      InvoiceNumber: invoiceNumber,
    }));
  }, [invoiceNumber]);

  const Subtotal = (itemFields || []).map((item) => item.HRS * item.Rate);
  const totalAmount = Subtotal.reduce((a, b) => a + b, 0);

  const senderData = invoiceData?.SenderInfo;
  const recieverData = invoiceData?.RecieverInfo;
  const EditedData = {
    InvoiceType: invoiceType === '' ? invoiceType : invoiceData?.InvoiceType,
    Currency: currency === {} ? currency : invoiceData?.Currency,
    InvoiceNumber: invoiceData?.InvoiceNumber,
    SenderResult: senderResult.length === 0 ? senderData : senderResult,
    RecieverResult: recieverResult.length === 0 ? recieverData : recieverResult,
    Dates: InvoiceDates,
    Items: itemFields,
    Amount: totalAmount,
  };
  const getInvoiceData = () => {
    fetch(`${configdata.REACT_APP_SERVER_URL}invoicedata/${InvoiceId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        setInvoiceData(data.data);
        setItemFields(data?.data?.Items);
      });
  };
  const getSenderInfo = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}senderinfo`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setSenderInfoData(data));
  };
  const getrecieverInfo = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}recieverinfo`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setRecieverInfoData(data));
  };

  useEffect(() => {
    getSenderInfo();
    getrecieverInfo();
    getInvoiceData();
  }, []);

  const updateInvoiceData = async (data) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
    const result = await fetch(
      `${configdata.REACT_APP_SERVER_URL}invoicedata/updateinvoice/${InvoiceId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify(data),
      },
    ).then((res) => res.json());
    if (result.status === 'ok') {
      history.push('/Admin/Invoice');
    } else {
      console.log(result.error);
    }
  };
  const updatedEditedData = async () => {
    // eslint-disable-next-line array-callback-return
    const removeEmptyItems = EditedData.Items.filter((item) => {
      if (item.Rate != '' && item.HRS != '') {
        return item;
      }
    });
    const EditedDataTwo = {
      InvoiceType: invoiceType === '' ? invoiceType : invoiceData?.InvoiceType,
      Currency: currency === {} ? currency : invoiceData?.Currency,
      InvoiceNumber: invoiceData?.InvoiceNumber,
      SenderResult: senderResult.length === 0 ? senderData : senderResult,
      RecieverResult: recieverResult.length === 0 ? recieverData : recieverResult,
      Dates: InvoiceDates,
      Items: removeEmptyItems,
      Amount: totalAmount,
    };
    updateInvoiceData(EditedDataTwo);
  };
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div id="page_content" className="content-main">
          <div className="content_invoice">
            <div id="page_invoice" className="invoice">
              <div className="content_top" style={{ flex: '1' }}>
                <div className="logo_img" style={{ marginTop: '0px' }}>
                  <h2>
                    <span className="logo_img">
                      <img
                        src="/images/logo.png"
                        alt=""
                        className="mobile_img"
                      />
                    </span>
                  </h2>
                </div>
                <div className="invoice_select" style={{ marginTop: '0px' }}>
                  <select
                    value={invoiceData?.Currency}
                    onChange={(e) => {
                      setCurrency((previous_data) => ({
                        ...previous_data,
                        currency: e.target.value,
                      }));
                    }}
                  >
                    <option value="" disabled selected>
                      Select Currency
                    </option>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </select>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flex: '0',
                    marginTop: '0',
                  }}
                >
                  <div className="invoice_select">
                    <select
                      value={invoiceData?.InvoiceType}
                      onChange={(e) => {
                        setInvoiceType((previous_data) => ({
                          ...previous_data,
                          InvoiceType: e.target.value,
                        }));
                      }}
                    >
                      {options.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="butns">
                  <div className="save_btn">
                    <button type="button" onClick={updatedEditedData}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
              {display || recieverDisplay ? (
                <div className="cmn_modal popup">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body">
                        <span
                          onClick={() => {
                            display === true
                              ? setDisplay(false)
                              : setRecieverDisplay(false);
                          }}
                          className="close"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            color="rgb(168, 156, 156)"
                          />
                        </span>
                        <ul className="modal_ul">
                          {display === true
                            ? senderInfoData.map((data1) => (
                              <li>
                                <div className="list_div">
                                  <div className="div_left">
                                    <FontAwesomeIcon icon={faUser} />
                                  </div>
                                  <div
                                    className="div_right"
                                    onClick={async () => {
                                      const id = data1._id;
                                      await fetch(
                                        `${configdata.REACT_APP_SERVER_URL}senderInfo/${id}`,
                                        {
                                          method: 'GET',
                                          mode: 'cors',
                                          cache: 'no-cache',
                                          credentials: 'same-origin',
                                          headers: {
                                            'Content-Type':
                                                'application/json',
                                            'x-auth-token': token,
                                          },
                                          redirect: 'follow',
                                          referrerPolicy: 'no-referrer',
                                        },
                                      )
                                        .then((res) => res.json())
                                        .then((data) => {
                                          setSenderResult(data.data);

                                          if (data.status === 'ok') {
                                            setDisplay(false);
                                          }
                                        });
                                    }}
                                  >
                                    <div>
                                      <h3>{data1.FirstName}</h3>
                                      <p>{data1.Email}</p>
                                    </div>
                                    <div
                                      style={{
                                        width: '60px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => setShowSuccess({
                                          ...showSuccess,
                                          should: true,
                                          id: data1._id,
                                          type: 'senderInfo',
                                          callback: getSenderInfo,
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        onClick={() => setEditInvoiceUserDetail({
                                          shouldOpen: true,
                                          id: data1._id,
                                          mode: 'senderInfo',
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))
                            : recieverInfoData.map((data2) => (
                              <li>
                                <div className="list_div">
                                  <div className="div_left">
                                    <FontAwesomeIcon icon={faUser} />
                                  </div>
                                  <div
                                    className="div_right"
                                    onClick={async () => {
                                      const id1 = data2._id;
                                      await fetch(
                                        `${configdata.REACT_APP_SERVER_URL}recieverInfo/${id1}`,
                                        {
                                          method: 'GET',
                                          mode: 'cors',
                                          cache: 'no-cache',
                                          credentials: 'same-origin',
                                          headers: {
                                            'Content-Type':
                                                'application/json',
                                            'x-auth-token': token,
                                          },
                                          redirect: 'follow',
                                          referrerPolicy: 'no-referrer',
                                        },
                                      )
                                        .then((res) => res.json())
                                        .then((data) => {
                                          setRecieverResult(data.data);
                                          if (data.status === 'ok') {
                                            setRecieverDisplay(false);
                                          }
                                        });
                                    }}
                                  >
                                    <div>
                                      <h3>{data2.FirstName}</h3>
                                      <p>{data2.Email}</p>
                                    </div>
                                    <div
                                      style={{
                                        width: '60px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        onClick={() => setShowSuccess({
                                          ...showSuccess,
                                          should: true,
                                          id: data2._id,
                                          type: 'recieverInfo',
                                          callback: getrecieverInfo,
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        onClick={() => setEditInvoiceUserDetail({
                                          shouldOpen: true,
                                          id: data2._id,
                                          mode: 'recieverInfo',
                                        })}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="content_center">
                <div className="center_row1">
                  <div className="heading_left">
                    <div
                      style={{ margin: '10px' }}
                      onClick={() => {
                        setDisplay(true);
                      }}
                    >
                      <p className="heading_text">From</p>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? invoiceData?.SenderInfo?.CompanyName
                            : senderResult.CompanyName}
                        </h4>
                      </span>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? invoiceData?.SenderInfo?.Email
                            : senderResult.Email}
                        </h4>
                      </span>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? invoiceData?.SenderInfo?.Address1
                            : senderResult.Address1}
                        </h4>
                      </span>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {senderResult.length === 0
                            ? invoiceData?.SenderInfo?.Phone
                            : senderResult.Phone}
                        </h4>
                      </span>
                    </div>
                  </div>
                  <div className="heading_right">
                    <div
                      style={{ margin: '10px' }}
                      onClick={() => setRecieverDisplay(true)}
                    >
                      <p className="heading_text">To</p>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? invoiceData?.RecieverInfo?.CompanyName
                            : recieverResult.CompanyName}
                        </h4>
                      </span>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? invoiceData?.RecieverInfo?.Email
                            : recieverResult.Email}
                        </h4>
                      </span>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? invoiceData?.RecieverInfo?.Address1
                            : recieverResult.Address1}
                        </h4>
                      </span>
                      <span>
                        <h4 style={{ color: 'gray' }}>
                          {recieverResult.length === 0
                            ? invoiceData?.RecieverInfo?.Phone
                            : recieverResult.Phone}
                        </h4>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="center_row2">
                  <div className="row2_left">
                    <div className="row2_input_field">
                      <p>Invoice No : </p>
                      <span className="row2_input">
                        <input
                          type="number"
                          value={invoiceData?.InvoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />
                      </span>
                    </div>

                    <div className="row2_input_field">
                      <p>Invoice Date : </p>
                      <span className="row2_input">
                        <input
                          type="date"
                          name="invoiceDate"
                          defaultValue={invoiceData?.Dates?.invoiceDate}
                          onChange={(e) => setInvoiceDate(e.target.value)}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="row2_right">
                    <div className="row2_input_field">
                      <p>Due:</p>
                      <span className="row2_input">
                        <input
                          type="date"
                          name="invoiceDueDate"
                          defaultValue={invoiceData?.Dates?.invoiceDueDate}
                          onChange={(e) => setInvoiceDueDate(e.target.value)}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="content_bottom">
                  <div className="content_bottom_heading">
                    <div className="bottom_heading_left">
                      <span>
                        <p>Item</p>
                      </span>
                    </div>
                    <div className="bottom_heading_right">
                      <span style={{}}>
                        <p>HRS/QTY</p>
                      </span>
                      <span>
                        <p>Rate</p>
                      </span>

                      <span style={{ textAlign: 'right' }}>
                        <p>Subtotal</p>
                      </span>
                    </div>
                  </div>
                  {itemFields?.map((data, i) => (
                    <div key={i}>
                      <div className="rate-field_row1 infoRow">
                        <div className="row1_left">
                          <div className="input_field">
                            <input
                              style={{ marginTop: '0px' }}
                              type="text"
                              name="Item"
                              defaultValue={data?.Item}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                        </div>
                        <div className="row1_right">
                          <div className="input_field">
                            <input
                              type="number"
                              defaultValue={data?.HRS}
                              name="HRS"
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div className="input_field">
                            <input
                              type="number"
                              defaultValue={data?.Rate}
                              name="Rate"
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </div>
                          <div
                            className="input_field"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <span style={{ marginRight: '6px' }}>
                              {invoiceData?.Currency}
                            </span>
                            <span>
                              {' '}
                              {data.HRS * data.Rate}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="rate-field_row1 infoRow">
                        <div className="row1_left">
                          <textarea
                            className="input_description textareaDesc"
                            type="text"
                            placeholder="Description"
                            name="Description"
                            defaultValue={data?.Description}
                            onChange={(e) => handleInputChange(e, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="content_bottom_add_new">
                    <span
                      style={{ marginTop: '5px', color: 'gray' }}
                      onClick={() => {
                        if (itemFields) {
                          setItemFields([
                            ...itemFields,
                            {
                              Description: '',
                              HRS: '',
                              Item: '',
                              Rate: '',
                            },
                          ]);
                        } else {
                          setItemFields([
                            {
                              Description: '',
                              HRS: '',
                              Item: '',
                              Rate: '',
                            },
                          ]);
                        }
                      }}
                    >
                      <p>Add New Invoice Item</p>
                    </span>
                  </div>
                </div>
                <div className="content_summaryy">
                  <div className="content_summary_body">
                    <div className="content_summary_heading">
                      <p>Invoice Summary</p>
                    </div>
                    <div className="summary_total">
                      <div className="summary_total_row1">
                        <h4>Subtotal</h4>
                        <h4 style={{ marginLeft: '5px' }}>
                          <span style={{ marginRight: '4px' }}>
                            {invoiceData?.Currency}
                          </span>
                          <span>{totalAmount}</span>
                        </h4>
                      </div>
                      <div className="summary_total_row2">
                        <h4>Total</h4>
                        <h4>
                          {invoiceData?.Currency}
                          <span style={{ marginLeft: '5px' }}>
                            {totalAmount}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {editInvoiceUserDetail.shouldOpen && (
            <EditInvoiceUserDetail
              id={editInvoiceUserDetail.id}
              mode={editInvoiceUserDetail.mode}
            />
          )}

          {showSuccess.should && (
            <SuccessModel>
              <div>
                <h3>Are you sure to delete it?</h3>
              </div>
              <div className={styles.Model__btn__container}>
                <button
                  type="button"
                  style={{ background: '#e74c3c', border: 'none' }}
                  onClick={() => setShowSuccess({ ...SuccesRawData })}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{ background: '#2ecc71', border: 'none' }}
                  onClick={() => {
                    deleteInvoiceUser(
                      showSuccess.id,
                      showSuccess.type,
                      showSuccess.callback,
                    );
                    setShowSuccess({ ...SuccesRawData });
                  }}
                >
                  Confirm
                </button>
              </div>
            </SuccessModel>
          )}
        </div>
        {loader ? <div id="cover-spin" /> : null}
      </div>
    </div>
  );
}

export default EditInvoice;
