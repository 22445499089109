import { useEffect, useState } from 'react';

const useFetch = (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    const fetchData = async () => {
      try {
        await fetch(url, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        })
          .then((res) => res.json())
          .then((data1) => {
            setApiData(data1);
            if (data1.status === 'ok') {
              setIsLoading(false);
              setServerError(false);
            }
          });
      } catch (error) {
        setServerError(error);
        setIsLoading(false);
        setApiData(null);
      }
    };

    fetchData();
  }, [url]);

  return { isLoading, apiData, serverError };
};

export default useFetch;
