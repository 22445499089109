import React, { useEffect, useState, useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import SinglePagePopup from '../components/SinglePagePopup';
import Sidebar from '../components/Sidebar';
import EditReportPopup from '../components/EditReportPopup';
import BlogContext from '../utils/Context';
import 'react-spring-modal/styles.css';
import '../css/Singlepage.css';
import configdata from '../config.json';

function ProjectSinglePage() {
  const { projects, projectDataa } = useContext(BlogContext);
  const [projectData, setProjectData] = useState([]);
  const projectID = localStorage.getItem('projectId');
  const projectName = localStorage.getItem('projectName');
  const [popup, setPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [reportPopup, setReportPopup] = useState([]);
  const [searchItem, setSearchItem] = useState([]);
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [isOpen, setOpen] = React.useState(false);
  const [id1, setId1] = useState('');
  const [popupInfo, setPopupInfo] = useState([]);
  const [deleteReport] = useState('true');
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [filterLoader, setFilterLoader] = useState(false);
  const [notData, setNotData] = useState(false);
  const [test, setTest] = useState(false);
  const [projectReport, setProjectReport] = useState([]);
  const [totalData, setTotalData] = useState();

  const [state, setState] = useState({
    items: Array.from({ length: 30 }),
    hasMore: true,
  });

  const token = localStorage.getItem('token');

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 4000);
  }, [loading]);

  useEffect(() => {
    setProjectReport((prevReport) => prevReport.filter((d) => d._id !== id1));
  }, [test]);

  const fetchMoreData = () => {
    if (state.items.length >= 500) {
      setState({ ...state, hasMore: false });
      return;
    }

    setTimeout(() => {
      setState({
        items: state.items.concat(Array.from({ length: 30 })),
      });
    }, 1000);
  };
  const data = {
    Project: projectName,
    Name: '',
    StartDate: 'Invalid date',
    EndDate: 'Invalid date',

  };

  const getProjectDetails = async (offset, limit) => {
    data.offset = offset;
    data.limit = limit;
    await fetch(`${configdata.REACT_APP_SERVER_URL}search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json(data))
      .then((data3) => {
        setTotalData(data3.total);
        const data0 = data3.result.filter((data4) => data4.Delete !== 'true');
        setProjectReport((prev) => [...prev, ...data0]);
        // setProjectReport(data0);
      });
  };

  const projectDatafnc = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}project/${projectID}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data2) => {
        setProjectData(data2);
      });
  };

  useEffect(() => {
    getProjectDetails(0, 10);

    projectDataa(setLoading);
  }, []);

  useEffect(() => {
    projectDatafnc();
  }, []);

  const EmployeeName = searchItem?.map((d) => d?.value);
  const ProjectName = projectReport?.map((p) => p?.Project);
  const searchedData = {
    Name: EmployeeName,
    Project: ProjectName,
    StartDate: moment(searchStartDate).format('YYYY-MM-DD'),
    EndDate: moment(searchEndDate).format('YYYY-MM-DD'),
  };

  const filteredData = async () => {
    setFilterLoader(true);
    setTimeout(() => {
      setFilterLoader(false);
    }, 3000);
    await fetch(`${configdata.REACT_APP_SERVER_URL}search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(searchedData),
    })
      .then(async (res) => res.json())
      .then((data5) => {
        if (data5.length === 0) {
          setNotData(true);
        } else {
          setNotData(false);
        }
        setFilterData(data, console.log());
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filteredData();
  };

  const pp = projectReport.filter(
    (ele, ind) => ind === projectReport.findIndex((elem) => elem.Emp_Name === ele.Emp_Name),
  );
  const EmployeeOptions = pp.map((d) => ({
    value: d.Emp_Email,
    label: d.Emp_Name,
  }));

  const DeletedData = {
    Delete: deleteReport,
  };

  const filterProject = projects.filter(
    (data1) => data1.ProjectName === projectName,
  );

  const filterProjectName = filterProject.map((d) => d.ProjectName);
  const filterClientName = filterProject.map((d) => d.ClientName);

  return (
    <div>
      <Sidebar />
      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div className="content-main">
          <div className="content-all">
            <div className="projects_complete_detials">
              <div className="client-details">
                <div>
                  <h2>
                    {projectData?.length === undefined
                      ? filterProjectName
                      : projectData?.ProjectName}
                  </h2>
                  <p>
                    Client:
                    <span className="clientinfo">
                      {projectData?.length === undefined
                        ? filterClientName
                        : projectData?.ClientName}
                    </span>
                  </p>
                </div>

                <p>
                  Team Involved:
                  {pp
                    .filter((d) => {
                      const del = d.Delete;
                      if (del === 'false') {
                        return d;
                      }
                      return false;
                    })
                    .map((d) => (
                      <span className="clientinfo">{d?.Emp_Name}</span>
                    ))}
                </p>
              </div>
              <div className="project-filters project-page-filters project-singlePage">
                <form onSubmit={handleSubmit}>
                  <div className="filter-form">
                    <div className="formgrid">
                      <input
                        className="header_datepicker"
                        placeholderText="Start Date"
                        value={searchStartDate}
                        type="date"
                        onChange={(date) => {
                          setSearchStartDate(date.target.value);
                        }}
                      />
                    </div>
                    <div className="formgrid">
                      <input
                        className="header_datepicker"
                        placeholderText="End Date"
                        value={searchEndDate}
                        type="date"
                        onChange={(date) => {
                          setSearchEndDate(date.target.value);
                        }}
                      />
                    </div>
                    <div className="formgrid">
                      <Select
                        placeholder="Employee Name"
                        value={searchItem}
                        onChange={setSearchItem}
                        options={EmployeeOptions}
                        isMulti
                      />
                    </div>

                    <div className="formgrid">
                      <button type="submit" className="submit search-btn" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="task_list"
              id="scrollableDiv"
              style={{
                marginTop: '1rem',
                minHeight: '77vh',
                height: '500px',
                overflow: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <ul className="listing">
                {notData ? (
                  <b
                    style={{
                      color: '#1d1d99',
                      position: 'absolute',
                      top: '35%',
                      left: '53%',
                    }}
                  >
                    No data found
                  </b>
                ) : filterData.length === 0 ? (
                  loading ? (
                    <InfiniteScroll
                      dataLength={projectReport.length}
                      next={() => {
                        getProjectDetails(projectReport?.length, 10);
                      }}
                      hasMore={projectReport.length < totalData}
                      scrollableTarget="scrollableDiv"
                      loader={
                        projectReport.length !== 0 ? (
                          <h4>Loading...</h4>
                        ) : (
                          <b
                            style={{
                              color: '#1d1d99',
                              position: 'absolute',
                              top: '35%',
                              left: '53%',
                            }}
                          >
                            No data found
                          </b>
                        )
                      }
                      endMessage={(
                        <p
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          {projectReport.length !== 0 ? (
                            <b
                              style={{
                                color: '#1d1d99',
                              }}
                            >
                              Yay! You have seen it all
                            </b>
                          ) : (
                            <b
                              style={{
                                color: '#1d1d99',
                                position: 'absolute',
                                top: '35%',
                                left: '53%',
                                fontSize: '1rem',
                              }}
                            >
                              No data found
                            </b>
                          )}
                        </p>
                      )}
                    >
                      {projectReport
                        .filter((d) => {
                          const del = d.Delete;
                          if (del === 'false') {
                            return d;
                          }
                          return false;
                        })
                        .map((d) => (
                          <li className="pzge_maincontent">
                            <div
                              className="left"
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                const reportPoupData = d;
                                setReportPopup(reportPoupData);
                                setPopup((previousDisplay) => !previousDisplay);
                              }}
                            >
                              <h2>
                                {moment(d.Date).format('DD/MM/YYYY')}
                                <span>
                                  By:
                                  {d.Emp_Name}
                                </span>
                              </h2>

                              <p>{d.Description.substring(0, 500)}</p>
                            </div>

                            <div className="right">
                              <p>
                                <Tooltip title="Time spent" arrow>
                                  <i
                                    className="fa fa-clock-o"
                                    aria-hidden="true"
                                    style={{
                                      marginRight: '6px',
                                    }}
                                  />
                                </Tooltip>
                                Time Spent :
                                {' '}
                                {d.TimeSpend}
                                hr
                              </p>

                              <p
                                style={{
                                  marginTop: '8px',
                                }}
                              >
                                <span
                                  style={{
                                    marginBottom: '8px',
                                  }}
                                >
                                  <span>
                                    <Tooltip title="Edit" arrow>
                                      <i
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        className="fa fa-edit"
                                        onClick={() => {
                                          setPopupInfo(d);

                                          setEditPopup(
                                            (previousDisplay) => !previousDisplay,
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </span>
                                <span>
                                  <span>
                                    <Tooltip title="Edit" arrow>
                                      <i
                                        className="fa fa-trash"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          const id0 = d._id;
                                          setId1(id0);
                                          setOpen(true);
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                </span>
                                <CenterModal
                                  isOpen={isOpen}
                                  onDismiss={() => setOpen(false)}
                                >
                                  <p>Are you sure you want to delete it?</p>

                                  <ModalCloseTarget>
                                    <div className="button-Wrap">
                                      <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={() => {
                                          fetch(
                                            `${configdata.REACT_APP_SERVER_URL}delete/${id1}`,
                                            {
                                              method: 'PUT',
                                              headers: {
                                                'Content-Type':
                                                  'application/json',
                                                'x-auth-token': token,
                                              },
                                              body: JSON.stringify(DeletedData),
                                            },
                                          )
                                            .then((res) => res.json())
                                            .then(() => {
                                              setTest(true);
                                            });
                                        }}
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        style={{
                                          marginLeft: '8px',
                                          backgroundColor: '#333',
                                        }}
                                        className="btn btn-default"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </ModalCloseTarget>
                                </CenterModal>
                              </p>
                            </div>
                          </li>
                        ))}
                    </InfiniteScroll>
                  ) : (
                    <div id="cover-spin" />
                  )
                ) : (
                  filterData.map((d) => (
                    <li className="pzge_maincontent">
                      <div
                        className="left"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          const reportPoupData = d;
                          setReportPopup(reportPoupData);
                          setPopup((previousDisplay) => !previousDisplay);
                        }}
                      >
                        <h2>
                          {moment(d.Date).format('DD/MM/YYYY')}
                          <span>
                            By:
                            {d.Emp_Name}
                          </span>
                        </h2>

                        <p>{d.Description.substring(0, 500)}</p>
                      </div>

                      <div className="right">
                        <p>
                          <Tooltip title="Time spent" arrow>
                            <i
                              className="fa fa-clock-o"
                              aria-hidden="true"
                              style={{
                                marginRight: '6px',
                              }}
                            />
                          </Tooltip>
                          Time Spent :
                          {' '}
                          {d.TimeSpend}
                          hr
                        </p>
                        <p>
                          <span
                            onClick={() => {
                              setPopupInfo(d);

                              setEditPopup(
                                (previousDisplay) => !previousDisplay,
                              );
                            }}
                          >
                            <span>
                              <Tooltip title="Edit" arrow>
                                <i className="fa fa-edit" />
                              </Tooltip>
                            </span>
                          </span>
                          <span>
                            <span
                              onClick={() => {
                                const id0 = d._id;
                                setId1(id0);
                                setOpen(true);
                              }}
                            >
                              <Tooltip title="Delete" arrow>
                                <i className="fa fa-trash" />
                              </Tooltip>
                            </span>
                          </span>
                          <CenterModal
                            isOpen={isOpen}
                            onDismiss={() => setOpen(false)}
                          >
                            <p>Are you sure you want to delete it?</p>

                            <ModalCloseTarget>
                              <div className="button-Wrap">
                                <button
                                  className="btn btn-default"
                                  onClick={async () => {
                                    await fetch(
                                      `${configdata.REACT_APP_SERVER_URL}delete/${id1}`,
                                      {
                                        method: 'PUT',
                                        headers: {
                                          'Content-Type': 'application/json',
                                          'x-auth-token': token,
                                        },
                                        body: JSON.stringify(DeletedData),
                                      },
                                    )
                                      .then((res) => res.json())
                                      .then((data1) => data1);
                                  }}
                                  type="button"
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    marginLeft: '8px',
                                    backgroundColor: '#333',
                                  }}
                                  className="btn btn-default"
                                >
                                  Close
                                </button>
                              </div>
                            </ModalCloseTarget>
                          </CenterModal>
                        </p>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            </div>
            {filterLoader ? <div id="cover-spin" /> : null}

            <div className="popup-Wrap">
              <SinglePagePopup
                popup={popup}
                setPopup={setPopup}
                reportPopup={reportPopup}
              />
            </div>
            <div className="popup-Wrap">
              <EditReportPopup
                editPopup={editPopup}
                setEditPopup={setEditPopup}
                popupInfo={popupInfo}
              />
            </div>
          </div>
        </div>
        <div className="copy">
          <p> © 2022 | All Right Reserved</p>
        </div>
      </div>
    </div>
  );
}

export default ProjectSinglePage;
