import React from 'react';
import '../css/SuccessModel.css';

function SuccessModel({ children }) {
  return (
    <div className="SuccessModel">
      <div className="SuccessModel_child_container">{children}</div>
    </div>
  );
}

export default SuccessModel;
