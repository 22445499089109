import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import '../../css/Sidebar.css';

function SidebarUser() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <div>
      <nav className="navbar-default navbar-static-top">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" onClick={showSidebar}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <h1 className="mobile-logo" style={{ display: 'none' }}>
            <a href="#/" className="logo_img">
              <img src="/images/white-logo.png" alt="" />
              {' '}

            </a>
          </h1>
        </div>
        <div className="clearfix" />
        <div className="navbar-default sidebar" role="navigation">
          <div className="sidebar-nav navbar-collapse">
            <h1>
              <a href="#/" className="logo_img">
                <img src="/images/white-logo.png" alt="" />
                {' '}
              </a>
            </h1>
            <ul className={sidebar ? 'navbarOpen' : 'sidebar_ul'} id="side-menu">
              <li>
                <div>
                  <Link to="/User/Project" style={{ textDecoration: 'none' }} className="hvr-bounce-to-right">
                    <i className="fa fa-indent nav_icon" />
                    <span className="nav-label">Project</span>
                  </Link>
                </div>
                {/* <span className="nav-label">
              <Link to="/User" style={{ textDecoration: "none" }}>
                Project
              </Link>
            </span> */}
              </li>
              <li
                onClick={() => {
                  localStorage.setItem('login', 'isloggedout');
                  localStorage.removeItem('loginInfo');
                }}
              >
                <div onClick={() => {
                  window.location.reload();
                  localStorage.clear();
                }}
                >
                  <Link to="/" style={{ textDecoration: 'none' }} className="hvr-bounce-to-right">
                    <i className="fa fa-sign-in nav_icon" />
                    <span
                      className="nav-label"

                    >
                      Log Out

                    </span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default SidebarUser;
