/* eslint-disable import/no-anonymous-default-export */

import configdata from '../config.json';

export default (ReportId, TrashData, callback) => {
  let response = false;
  if (ReportId) {
    fetch(`${configdata.REACT_APP_SERVER_URL}delete/${ReportId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(TrashData),
    }).then((res) => {
      res.json().then(() => {
        response = true;
        if (callback) {
          callback();
        }
      });
    });

    if (response) {
      return true;
    }
  } else {
    response = true;
    if (response) {
      return false;
    }
  }
};
