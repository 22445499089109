/* eslint-disable no-unused-expressions */
import React, { useState, useContext } from 'react';
// import FileBase64 from "react-file-base64";
import axios from 'axios';
import BlogContext from '../utils/Context';
import '../css/SinglepagePopup.css';
import configdata from '../config.json';

function UploadPicPopup() {
  const { setempImg, setUploadPopup, getUserImage } = useContext(BlogContext);
  const [err] = useState(false);
  const [errMessage] = useState('file size should be under 500kb');
  const [dp, setdp] = useState(true);
  const [success, setsuccess] = useState(false);
  const [state, setState] = useState({
    file: null,
  });

  // const uploadImg = async (e) => {
  //   console.log(localStorage.getItem("empId"));
  //   axios
  //     .post(
  //       `${REACT_APP_SERVER_URL}imgUpload/${localStorage.getItem("empId")}`,
  //       {
  //         // imageId : localStorage.getItem("empId"),
  //         image: empImg
  //       }
  //     )
  //     .then((res) => console.log(res));
  // const empId = localStorage.getItem("empId");
  // console.log(empId);
  // const upEmp = await fetchEmp(empId);
  // const newEmp = {
  //   ...upEmp,
  //   image: empImg
  // };
  // console.log("hello", newEmp);

  // const res = await axios
  //   .patch(`${REACT_APP_SERVER_URL}app/employee/update/${empId}`, {
  //     image: empImg
  //   })
  //   .then((res) => {
  //     console.log("round 2", res.data);
  //     setempImg(res.data.data.image);
  //   });
  // };

  return (
    <div style={{ display: dp ? 'block' : 'none' }}>
      <div className="cmn_modal popup">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const formData = new FormData();
                formData.append('myImage', state.file);
                formData.append('userId', localStorage.getItem('empId'));
                const config = {
                  headers: {
                    'content-type': 'multipart/form-data',
                  },
                };
                await axios
                  .post(
                    `${configdata.REACT_APP_SERVER_URL}imgUpload/${localStorage.getItem(
                      'empId',
                    )}`,
                    formData,
                    config,
                  )
                  .then(() => {
                    getUserImage();
                    window.location.reload();
                  });
              }}
            >
              <div
                className="modal-body"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <h3 style={{ marginBottom: '30px' }}>Choose Profile Pic</h3>
                  <div
                    onClick={() => {
                      setdp(false);
                      setUploadPopup(false);
                    }}
                  >
                    <i className="fas fa-times" />
                  </div>
                </div>
                <input
                  type="file"
                  multiple={false}
                  onChange={(e) => {
                    setempImg(e.target.files[0]);
                    setState((previousState) => ({
                      ...previousState,
                      file: e.target.files[0],
                    }));
                  }}
                />
                {err ? (
                  <p style={{ marginTop: '10px', color: '#e74c3c' }}>
                    {errMessage}
                  </p>
                ) : null}
                <button
                  disabled={err}
                  className="login-sub"
                  onClick={() => {
                    setTimeout(() => {
                      setsuccess(true);
                    }, 1000);
                  }}
                  style={{
                    marginTop: '20px',
                    width: '125px',
                    background: err ? '#a29bfe' : null,
                    cursor: err ? 'not-allowed' : 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: success ? '#2ecc71' : null,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  type="submit"
                >
                  {success ? 'Uploaded ' : 'Upload '}
                  {success ? (
                    <i className="fas fa-check-circle" />
                  ) : (
                    <i className="fas fa-cloud-upload-alt" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadPicPopup;
