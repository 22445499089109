// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import configData from './config.json';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: configData.FIREBASE_API_KEY,
  authDomain: configData.FIREBASE_AUTHDOMAIN,
  projectId: configData.FIREBASE_PROJECTID,
  storageBucket: configData.FIREBASE_STORAGEBUCKET,
  messagingSenderId: configData.FIREBASE_MESSAGING_SENDERID,
  appId: configData.APPID,
  measurementId: configData.FIREBASE_MEASUREMENTID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
