export const Year = [
  {
    label: '2025',
    value: '2025',
  },
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2020',
    value: '2020',
  },
];

export const Month = [
  {
    label: 'January',
    value: '01',
  },
  {
    label: 'February',
    value: '02',
  },
  {
    label: 'March',
    value: '03',
  },
  {
    label: 'April',
    value: '04',
  },
  {
    label: 'May',

    value: '05',
  },
  {
    label: 'June',
    value: '06',
  },
  {
    label: 'July',

    value: '07',
  },
  {
    label: 'August',

    value: '08',
  },
  {
    label: 'September',

    value: '09',
  },
  {
    label: 'October',

    value: '10',
  },
  {
    label: 'November',

    value: '11',
  },
  {
    label: 'December',

    value: '12',
  },
];
