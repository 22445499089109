import React from 'react';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';

export default function ExportToExcel({ apiData, fileName }) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData1, fileName1) => {
    const ws = XLSX.utils.json_to_sheet(apiData1);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName1 + fileExtension);
  };

  return (
    <button
      type="button"
      style={{
        width: '10rem',
      }}
      onClick={(e) => exportToCSV(apiData, fileName)}
      className="login-sub"
    >
      Export to CSV
    </button>
  );
}
