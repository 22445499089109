/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import '../css/SinglepagePopup.css';

function SinglePagePopup({ popup, setPopup, reportPopup }) {
  return (
    <div>
      {popup ? (
        <div
          className="popup_content singlepopupWrap"
          style={{ position: 'fixed', padding: '0px' }}
        >
          <div className="singlePopup">
            <span
              style={{ cursor: 'pointer', top: '2rem' }}
              className="closeIcon"
              onClick={() => {
                setPopup((previousDisplay) => !previousDisplay);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>

            <div className="headerr">
              <span
                style={{
                  fontWeight: '600',
                  fontSize: '1rem',
                  color: '#1d1d99',
                }}
              >
                {reportPopup.Project}
              </span>

              <span
                style={{
                  marginTop: '1rem',
                  fontWeight: '500',
                  fontSize: '18px',
                }}
              >
                By:
                {reportPopup.Emp_Name}
              </span>

              <p style={{ fontSize: '1rem', marginTop: '1rem' }}>
                Date:
                {moment(reportPopup.Date).format('DD-MM-YY')}
              </p>
            </div>
            <div className="popup_maincontent">
              <ul>
                <li style={{ color: '' }}>{reportPopup.Description}</li>
              </ul>
              <p
                style={{
                  fontWeight: '500',
                  fontSize: '.8rem',
                  color: '#1d1d99',
                  marginTop: '1rem',
                }}
              >
                <i
                  className="fa fa-clock-o"
                  aria-hidden="true"
                  style={{ marginRight: '6px' }}
                />
                Time Spent :
                {' '}
                {reportPopup.TimeSpend}
                hr
              </p>
            </div>
            <div className="popup_footer" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SinglePagePopup;
