import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import '../css/Employee.css';
import { CenterModal, ModalCloseTarget } from 'react-spring-modal';
import AddEmployee from '../components/AddEmployee';
import EditEmployee from '../components/EditEmployee';
import Sidebar from '../components/Sidebar';
import 'react-spring-modal/styles.css';
import BlogContext from '../utils/Context';
import configdata from '../config.json';

function Employee({ history }) {
  const {

    setEmpData,

  } = useContext(BlogContext);
  const [employee, setEmployee] = useState([]);

  const [Display, setDisplay] = useState(false);
  const [editDisplay, setEditDisplay] = useState(false);

  const [employeeInfo, setEmployeeInfo] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [isOpen, setOpen] = React.useState(false);
  const [id1, setId1] = useState('');
  const [searchEmployee, setSearchEmployee] = useState('');
  const [deleteEmployee] = useState('true');
  const [loading, setLoading] = useState(false);
  const str = searchEmployee;
  const cleanStr = str.trim();

  const token = localStorage.getItem('token');

  const EmployeeData = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}app/employee`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    })
      .then((res) => res.json())
      .then((data) => setEmployee(data))
      .catch((error) => error);
    setLoading(true);
  };

  useEffect(() => {
    EmployeeData();
  }, []);

  const DeletedData = {
    Delete: deleteEmployee,
  };
  return (
    <div>
      <Sidebar />

      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div className="content-main">
          <div className="content-all">
            <div className="employee_form">
              <div className="e_search_form">
                <form className="navbar-left-right">
                  <div className="searchbar">
                    <i className="fa fa-search" aria-hidden="true" />
                    <input
                      placeholder="Search"
                      type="text"
                      onChange={(e) => {
                        setSearchEmployee(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="add_newbtn">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setDisplay((previousDisplay) => !previousDisplay);
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true" />
                  Add New
                </span>
              </div>
            </div>
            <div
              className="employee_list empoyee-adminlist"
              style={{ marginTop: '1rem', minHeight: '77vh' }}
            >
              <ol className="listing">
                {loading ? (
                  employee
                    .filter((d) => {
                      const del = d.Delete;
                      if (del === 'false') {
                        return d;
                      }
                      return false;
                    })

                    .filter((d) => {
                      if (cleanStr === '') {
                        return d;
                      }
                      if (
                        `${d.FirstName} ${d.LastName}`
                          .toLowerCase()
                          .includes(cleanStr.toLowerCase())
                      ) {
                        return d;
                      }
                      return false;
                    })
                    .map((d) => (
                      <li>
                        <div className="listinerrWrap">
                          <div className="left">
                            <h2
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setEmpData([]);
                                const employee1 = d.Email;
                                localStorage.setItem(
                                  'employeeEmail',
                                  employee1,
                                );
                                history.push({
                                  pathname: '/Admin/Employee/SinglePage',
                                  state: {
                                    empName: `${d.FirstName} ${d.LastName}`,
                                  },
                                });
                              }}
                            >
                              {d.FirstName}
                              {' '}
                              {d.LastName}
                            </h2>
                          </div>
                          <div className="right">
                            <p
                              style={{
                                width: '100px',
                                display: 'flex',
                                justifyContent: 'space-around',
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                <span>
                                  <span
                                    href="#/"
                                    onClick={() => {
                                      const id = d._id;
                                      const data = d;
                                      setEmployeeId(id);
                                      setEditDisplay(
                                        (previousDisplay) => !previousDisplay,
                                      );
                                      setEmployeeInfo(data);
                                    }}
                                  >
                                    <i className="fa fa-edit" />
                                  </span>
                                </span>
                              </Tooltip>

                              <Tooltip title="Delete" arrow>
                                <span>
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      const id0 = d._id;
                                      setId1(id0);
                                      setOpen(true);
                                    }}
                                  >
                                    <i className="fa fa-trash" />
                                  </span>
                                </span>
                              </Tooltip>
                            </p>
                          </div>
                          <CenterModal
                            isOpen={isOpen}
                            onDismiss={() => setOpen(false)}
                          >
                            <p>Are you sure you want to delete it?</p>

                            <ModalCloseTarget>
                              <div className="button-Wrap">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  onClick={() => {
                                    if (id1 === '60c6ee8fe427651f1530f9da') {
                                      alert('Admin cannot be deleted');
                                    } else {
                                      fetch(
                                        `${configdata.REACT_APP_SERVER_URL}app/employee/delete/${id1}`,
                                        {
                                          method: 'PUT',
                                          headers: {
                                            'Content-Type': 'application/json',
                                            'x-auth-token': token,
                                          },
                                          body: JSON.stringify(DeletedData),
                                        },
                                      ).then((res) => res.json());
                                    }
                                    setTimeout(() => {
                                      window.location.reload();
                                    }, 1000);
                                  }}
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  style={{
                                    marginLeft: '8px',
                                    backgroundColor: '#333',
                                  }}
                                  className="btn btn-default"
                                >
                                  Close
                                </button>
                              </div>
                            </ModalCloseTarget>
                          </CenterModal>
                        </div>
                      </li>
                    ))
                ) : (
                  <div id="cover-spin" />
                )}
              </ol>
            </div>
          </div>
        </div>
        <div className="copy">
          <p> © 2022 | All Rights Reserved</p>
        </div>
      </div>

      <AddEmployee Display={Display} setDisplay={setDisplay} />
      <EditEmployee
        editDisplay={editDisplay}
        setEditDisplay={setEditDisplay}
        employeeInfo={employeeInfo}
        employeeId={employeeId}
        EmployeeData={EmployeeData}
      />
    </div>
  );
}

Employee.propTypes = {
  history: PropTypes.object,
};
export default Employee;
