import React, { useState } from 'react';
import '../css/Sidebar.css';
import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function Sidebar() {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/Admin/');

  return (
    <div>
      <nav className="navbar-default navbar-static-top">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target=".navbar-collapse"
            onClick={showSidebar}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <h1 className="mobile-logo">
            <a href="#/" className="logo_img">
              <img src="/images/white-logo.png" alt="" />
            </a>
          </h1>
        </div>
        <div className="navbar-default sidebar" role="navigation">
          <div className="sidebar-nav navbar-collapse">
            <h1>
              <a href="#/" className="logo_img">
                <img src="/images/white-logo.png" alt="" />
              </a>
            </h1>
            <ul
              className={sidebar ? 'navbarOpen' : 'sidebar_ul'}
              id="side-menu"
            >
              <li>
                <div>
                  <Link
                    to="/Admin/Dashboard"
                    style={{ textDecoration: 'none' }}
                    className="hvr-bounce-to-right"
                  >
                    <i
                      className="fas fa-chart-line nav_icon"
                      id={splitLocation[1] === 'Dashboard' ? 'active2' : ''}
                    />
                    <span className="nav-label">Dashboard</span>
                  </Link>
                </div>
                <div>
                  <Link
                    to="/Admin/Project"
                    style={{ textDecoration: 'none' }}
                    className="hvr-bounce-to-right"
                  >
                    <i
                      className="fa fa-indent nav_icon"
                      id={splitLocation[1] === 'Project' ? 'active0' : ''}
                    />
                    <span className="nav-label">Project</span>
                  </Link>
                </div>

                <div>
                  <Link
                    to="/Admin/Employee"
                    style={{ textDecoration: 'none' }}
                    className="hvr-bounce-to-right"
                  >
                    <i
                      className="fa fa-users nav_icon"
                      id={splitLocation[1] === 'Employee' ? 'active1' : ''}
                    />
                    <span className="nav-label">Employees</span>
                  </Link>
                </div>

                <div>
                  <Link
                    to="/Admin/Reports"
                    style={{ textDecoration: 'none' }}
                    className="hvr-bounce-to-right"
                  >
                    <i
                      className="fa fa-file nav_icon"
                      id={splitLocation[1] === 'Reports' ? 'active2' : ''}
                    />
                    <span className="nav-label">Reports</span>
                  </Link>
                </div>

                <div>
                  <Link
                    to="/Admin/Invoice"
                    style={{ textDecoration: 'none' }}
                    className="hvr-bounce-to-right"
                  >
                    <i
                      className="fa fa-file nav_icon"
                      id={splitLocation[1] === 'Invoice' ? 'active2' : ''}
                    />
                    <span className="nav-label">Invoices</span>
                  </Link>
                </div>
              </li>

              <li
                onClick={() => {
                  localStorage.setItem('login', 'isloggedout');
                }}
              >
                <div
                  onClick={() => {
                    window.location.reload();
                    localStorage.clear();
                  }}
                >
                  <Link
                    to="/"
                    style={{ textDecoration: 'none' }}
                    className="hvr-bounce-to-right"
                  >
                    <i
                      className="fa fa-sign-in nav_icon"
                      onClick={() => {
                        localStorage.setItem('login', 'isloggedout');
                      }}
                    />
                    <span
                      className="nav-label"
                      onClick={() => {
                        localStorage.removeItem('loginInfo');
                        localStorage.clear();
                      }}
                    >
                      Log Out

                    </span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
