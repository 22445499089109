/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/AddEmployee.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Modal from './Modal';
import configdata from '../config.json';

const options = [
  { value: 'Front End Designer', label: 'Front End Designer' },
  { value: 'Web Developer', label: 'Web Developer' },
  { value: 'WebApp Developer', label: 'WebApp Developer' },
  { value: 'Mobile App Developer', label: 'Mobile App Developer' },
  { value: 'Graphic Designer', label: 'Graphic Designer' },
];
const schema = yup.object().shape({
  FirstName: yup
    .string()
    .trim('The First name cannot include  spaces')
    .strict(true)
    .required('First Name should be required ⚠️'),
  LastName: yup
    .string()
    .required('Last Name should be required ⚠️')
    .trim('The Last name cannot include  spaces')
    .strict(true),
  Email: yup
    .string()
    .required('Email should be required ⚠️')
    .trim('The Email cannot include  spaces')
    .strict(true),
  Password: yup
    .string()
    .required('Password should be required ⚠️')
    .min(6, 'Your password must be longer than 6 characters.')
    .trim('The Password cannot include  spaces')
    .strict(true),
  DOB: yup.string().required('DOB should be required ⚠️'),
  Designation: yup.string().required('Designation should be required ⚠️'),
  ContactNumber: yup.string().required('ConatctNumber should be required ⚠️'),
});
function AddEmployee({ display, setDisplay, Display }) {
  const [passwordShown, setPasswordShown] = useState(false);

  const [modal, setModal] = useState(false);

  const [emailErr, setEmailErr] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const token = localStorage.getItem('token');
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const submitForm = async (data) => {
    const employeeData = {
      FirstName: data.FirstName,
      LastName: data.LastName,
      Email: data.Email.toLowerCase(),
      Password: data.Password,
      DOB: moment(data.DOB).format('YYYY-MM-DD'),
      Designation: data.Designation,
      ContactNumber: data.ContactNumber,
    };
    const email = data.Email.toLowerCase();
    const output = await fetch(
      `${configdata.REACT_APP_SERVER_URL}app/emailexsist`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          email,
        }),
      },
    ).then((res) => res.json());
    if (output.status === 'ok') {
      const result = await fetch(
        `${configdata.REACT_APP_SERVER_URL}app/addemployee`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
          body: JSON.stringify(employeeData),
        },
      ).then((response) => response.json());
      if (result.status === 'ok') {
        setDisplay((previousDisplay) => !previousDisplay);
        setModal((previousDisplay) => !previousDisplay);
      } else {
        console.log(result.error);
      }
    } else {
      setEmailErr(output.error);
    }
  };

  return (
    <div>
      {Display ? (
        <div className="cmn_modal popup" style={{ display }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <span
                  onClick={() => {
                    setDisplay((prevDisplay) => !prevDisplay);
                  }}
                  className="close"
                >
                  <FontAwesomeIcon icon={faTimes} color="rgb(168, 156, 156)" />
                </span>

                <form
                  className="employee_details"
                  onSubmit={handleSubmit(submitForm)}
                >
                  <div className="col-md-12">
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="First Name "
                          name="FirstName"
                          {...register('FirstName', {
                            required: true,
                          })}
                          type="text"
                        />
                        <i className="fa fa-user" />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.FirstName?.message}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Last Name "
                          name="LastName"
                          type="text"
                          {...register('LastName', {
                            required: true,
                          })}
                        />
                        <i className="fa fa-user" />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.LastName?.message}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Email "
                          name="Email"
                          type="text"
                          {...register('Email', {
                            required: true,
                          })}
                        />
                        <i className="fa fa-envelope" />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.Email?.message}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Password"
                          name="Password"
                          type={passwordShown ? 'text' : 'password'}
                          {...register('Password', {
                            required: true,
                          })}
                        />
                        <i
                          className="fa fa-eye"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={togglePasswordVisiblity}
                        />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.Password?.message}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          type="date"
                          id="birthday"
                          name="birthday"
                          {...register('DOB', {
                            required: true,
                          })}
                        />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.DOB?.message}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <select
                          name="Designation"
                          id="Designation"
                          {...register('Designation', { required: true })}
                        >
                          <option value="null" disabled selected>
                            Designation
                          </option>
                          {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.Designation?.message}
                      </p>
                    </div>
                    <div className="input-fieldWrap">
                      <div className="form-field">
                        <input
                          placeholder="Contact Number"
                          type="number"
                          min="0"
                          name="ContactNumber"
                          {...register('ContactNumber', { required: true })}
                        />
                        <i className="fa fa-phone" />
                      </div>
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.ContactNumber?.message}
                      </p>
                    </div>
                    <div className="login-do">
                      <button
                        style={{ color: 'white' }}
                        type="submit"
                        className="login-sub adddetail-user"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <h4
                style={{
                  color: 'red',
                  marginBottom: '2rem',
                  textAlign: 'center',
                  fontWeight: '600',
                }}
              >
                {emailErr}
              </h4>
            </div>
          </div>
        </div>
      ) : null}
      <Modal modal={modal} setModal={setModal} />
    </div>
  );
}

export default AddEmployee;
