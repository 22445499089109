/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import EditReportPopup from '../../components/EditReportPopup';
import Modal from '../../components/Modal';
import UserSidebar from '../../components/Users/SidebarUser';
import UploadPicPopup from '../../components/UploadPicPopup';
import BlogContext from '../../utils/Context';
import ChangePasswordModal from '../../components/Modal/ChangePasswordModal';
import './userProject.css';
import configdata from '../../config.json';

const schema = yup.object().shape({
  Date: yup.string().required(),

  Description: yup
    .string()
    .required('Description should be required ⚠️')
    .strict(true),
  TimeSpend: yup.string().required(),
});

function UserProject() {
  const [modal, setModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [totalData, setTotalData] = useState();
  const { editPopup, setEditPopup } = useContext(BlogContext);
  const [popupInfo, setPopupInfo] = useState([]);
  const {
    setemp_Email,
    empReport,
    setEmpReport,
    btnLoader,
    setBtnLoader,
    uploadPopup,
    setUploadPopup,
  } = useContext(BlogContext);
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const token = localStorage.getItem('token');
  const firebasetoken = localStorage.getItem('firebasetoken');
  const filterProjet = project.label;

  const userName = localStorage.getItem('Name');
  const userEmail = localStorage.getItem('Emp_Email');
  setemp_Email(userEmail);

  const [projectData, setProjectData] = useState([]);
  const [userImage, setUserImage] = useState();
  const [employeeData, setEmployeeData] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const empId = localStorage.getItem('empId');

  const getUserImage = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}img/${empId}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => {
        setUserImage(data);
      });
  };
  const projectdata = async () => {
    await fetch(`${configdata.REACT_APP_SERVER_URL}project`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.json())
      .then((data) => setProjectData(data));
    setLoading(true);
  };

  const subscribe = async () => {
    const obj = {
      firebase: firebasetoken,
      name: userName,
    };

    await fetch(`${configdata.REACT_APP_SERVER_URL}subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

      },

      body: await JSON.stringify(obj),

    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };

  const submitForm = async (data, e) => {
    const reportData = {
      Emp_Name: userName,
      Emp_Email: userEmail,
      Date: moment(data.Date).format('YYYY-MM-DD'),
      Description: data.Description.trim(),
      Project: filterProjet,
      TimeSpend: data.TimeSpend,
    };

    const result = await fetch(`${configdata.REACT_APP_SERVER_URL}addreport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(reportData),
    }).then((res) => {
      reset();
      return res.json();
    });

    if (result.status === 'ok') {
      setModal((previousDisplay) => !previousDisplay);
    } else {
      setError(result.error);
    }

    await fetch(`${configdata.REACT_APP_SERVER_URL}senddailyreport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(reportData),
    })
      .then((res) => res.json())
      .then((data1) => data1);
    await subscribe();
  };

  const userData = {
    Email: userEmail,
  };
  let test;
  const dailyReportData = async (offset, limit) => {
    userData.Offset = offset;
    userData.Limit = limit;
    await fetch(`${configdata.REACT_APP_SERVER_URL}search/empreport`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify(userData),
    })
      .then((res) => res.json())
      .then((data) => {
        setTotalData(data.total);

        setEmpReport((prev) => [...prev, ...data.result]);
        // setEmpReport(data.result, console.log());
      });
  };

  console.log(totalData, 'data');
  const ProjectOptions = projectData.filter((d) => d.Delete === 'false');

  const LabeledProjectOptions = ProjectOptions.map((d) => ({
    value: d._id,
    label: d.ProjectName,
  }));

  useEffect(() => {
    projectdata();
  }, []);

  useEffect(() => {
    dailyReportData(0, 10);
  }, []);

  useEffect(() => {
    getUserImage();
  }, []);

  const filteredEmpResult = empReport.filter((data) => data.Delete === 'false');

  if (btnLoader) {
    setTimeout(() => {
      setBtnLoader(false);
    }, 2000);
  }

  return (
    <div>
      <UserSidebar />

      <div id="page-wrapper" className="gray-bg dashbard-1">
        <div className="navbar_profile_name">
          <div className="navbar_img">
            {' '}
            <img
              src={userImage?.data}
              alt="logo"
              onClick={() => setUploadPopup(true)}
            />
            <h3>{userName}</h3>
          </div>
          <div
            className="setting_icon"
            onClick={() => setChangePassword((previousDisplay) => !previousDisplay)}
          >
            <FontAwesomeIcon icon={faCog} />
          </div>
        </div>

        <div className="content-main">
          <div className="content-all">
            <div className="projects_complete_detials">
              <div className="project-filters userProject-page-filters">
                <form onSubmit={handleSubmit(submitForm)}>
                  <div className="filter-form">
                    <div className="form-field">
                      <input
                        type="date"
                        id="birthday"
                        name="birthday"
                        {...register('Date', {
                          required: true,
                        })}
                      />

                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {errors.Date?.message}
                      </p>
                    </div>

                    <div className="formgrid">
                      <Select
                        placeholder="Project Name"
                        value={project}
                        onChange={(data) => {
                          setProject(data);
                          setError('');
                        }}
                        options={LabeledProjectOptions}
                      />
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                          fontSize: '12px',
                        }}
                      >
                        {error || null}
                      </p>
                    </div>

                    <div className="formgrid">
                      <textarea
                        style={{ height: '10rem' }}
                        placeholder="Add Task Description Here"
                        name="Description"
                        type="text"
                        {...register('Description', {
                          required: true,
                        })}
                      />

                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                        }}
                      >
                        {errors.Description?.message}
                        {' '}
                      </p>
                    </div>

                    <div className="formgrid">
                      <input
                        className=""
                        placeholder="Time Spent In Hours"
                        type="number"
                        min="0"
                        step="0.5"
                        name="TimeSpend"
                        {...register('TimeSpend', {
                          required: true,
                        })}
                      />
                      <p
                        style={{
                          color: 'red',
                          marginBottom: '1rem',
                        }}
                      >
                        {errors.TimeSpend?.message}
                      </p>
                    </div>
                  </div>

                  <div
                    className="div_button"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItem: 'center',
                    }}
                  >
                    <button className="submit_button login-sub" type="submit">
                      Submit
                    </button>
                    {btnLoader && (
                      <div style={{ marginTop: '35px', marginLeft: '30px' }}>
                        <CircularProgress />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div
              className="task_list"
              id="scrollableDiv"
              style={{
                marginTop: '1rem',
                minHeight: '77vh',
                height: '500px',
                overflow: 'scroll',
                overflowX: 'hidden',
              }}
            >
              <ol className="listing">

                <InfiniteScroll
                  dataLength={filteredEmpResult.length}
                  next={() => {
                    dailyReportData(filteredEmpResult?.length, 10);
                  }}
                  hasMore={filteredEmpResult.length < totalData}
                  scrollableTarget="scrollableDiv"
                  loader={<h4>Loading...</h4>}
                >
                  {filteredEmpResult.map((d) => (
                    <li>
                      <div className="left">
                        <h2>
                          Date:
                          {moment(d.Date).format('DD-MM-YYYY')}
                        </h2>
                        <h4>{d.Project}</h4>
                        <p>
                          Description:
                          {d.Description}
                        </p>
                      </div>
                      <div className="right">
                        <p>
                          <span>
                            <p
                              style={{
                                fontWeight: '500',
                                fontSize: '.8rem',
                                color: '#1d1d99',
                              }}
                            >
                              <i
                                className="fa fa-clock-o"
                                aria-hidden="true"
                                style={{
                                  marginRight: '6px',
                                }}
                              />
                              {d.TimeSpend}
                              hrs
                            </p>
                            <span
                              onClick={() => {
                                setPopupInfo(d);

                                setEditPopup(
                                  (previousDisplay) => !previousDisplay,
                                );
                              }}
                            >
                              <i className="fa fa-edit" />
                            </span>
                          </span>
                        </p>
                      </div>
                    </li>
                  ))}

                </InfiniteScroll>

              </ol>
            </div>
          </div>
          <div className="popup-Wrap">
            <EditReportPopup
              editPopup={editPopup}
              setEditPopup={setEditPopup}
              popupInfo={popupInfo}
            />
          </div>
          {uploadPopup && <UploadPicPopup />}
        </div>
        <div className="copy">
          <p> 2021 | All Right Reserved</p>
        </div>
        <ChangePasswordModal
          passwordModal={changePassword}
          setPasswordModal={setChangePassword}
        />
      </div>
      <Modal
        modal={modal}
        setModal={setModal}
        refetchType="Refetch_Emp_Report"
      />
    </div>
  );
}

export default UserProject;
